.index-container {
    .title {
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        color: #2F3438;
    }

    .no-data-img {
        display: block;
        margin: 70px auto 0;
    }

    .desc {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        margin-top: 8px;
        color: rgba(47, 52, 56, .6)
    }
    .index-banner-container {
        width: 100%;
        height: 480px;
        background: #200804;
        background: url('../../resource/images/index/banner-bg.svg') no-repeat center center;
        .banner-content {
            height: 100%;
            margin: 0 auto;
            .banner-img {
                width: 100%;
                height:480px;
            }
        }
        .ant-carousel .slick-slide img {
            display: block;
            width: 100%;
            height: 480px;
        }
        .slick-dots-bottom {
            bottom: 45px;
        }
    }

    .first-line {
        border-bottom: 1px solid rgba(47, 52, 56, .1);
    }
    .right-line {
        border-right: 1px solid rgba(47, 52, 56, .1);
    }


    .current-info-box {
        background: #FFF;
        padding-bottom: 71px;
        .current-number {
            margin: 0 auto;
            position: relative;
            top: -42px;
            .ant-col-5 {
                flex: 0 0 20%;
            }
            .number-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 88px;
                background: #FFFFFF;
                box-shadow: 0 0 8px #E0E0E0;
                .item-icon {
                    margin-right: 18px;
                    margin-left: 18px;
                }
                .text-box {
                    .number-text {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 34px;
                        color: #C48B3A;
                        word-break: break-all;
                    }
                    .desc-text {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(47, 52, 56, .6);
                    }
                }
            }
        }
        .product-service {
            margin: 41px auto 0;
            .project-item {
                text-align: center;
                .product-text {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 25px;
                    margin-top: 24px;
                    margin-bottom: 8px;
                }
                .product-link {
                    font-size: 14px;
                    line-height: 20px;
                    color: #C48B3A;
                    &:hover {
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .core-services {
        background: linear-gradient(180deg, #F8F8F8 0%, #F6F6F7 102.4%);
        .title-box {
            height: 305px;
            background: url("../../resource/images/index/img-core-bg.svg") no-repeat center top;
            background-size: auto 100%;
            background-color: #ff2c38;
            text-align: center;
            .title-text {
                font-weight: 500;
                font-size: 28px;
                line-height: 42px;
                color: #FFFFFF;
                padding-top: 71px;
                margin-bottom: 8px;
            }
            .title-desc {
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: rgba(253, 253, 253, .8);
            }
        }
        .core-item-box {
            margin-top: 36px;
            .core-item {
                margin: 0 auto;
                display: flex;
                justify-content: flex-start;
                background: #FFF;
                box-shadow: 0 0 16px #EBEBEB;
                .aside-info {
                    width: 22.22%;
                    height: 420px;
                    padding: 48px 36px 0 24px;
                    position: relative;
                    background: linear-gradient(180deg, #F9F9F9 0%, rgba(248, 252, 255, 0) 100%, #FFFFFF 100%);
                    .aside-title {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        color: #2F3438;
                        margin-bottom: 17px;
                    }
                    .desc {
                        padding: 0 8px 0 6px;
                        background: url('../../resource/images/index/icon-desc-bg.svg') no-repeat left center;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: #FFFFFF;
                        margin-bottom: 6px;
                        text-align: left;
                    }
                    .text {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(47, 52, 56, .6);
                    }
                    .aside-img {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
                .core-item-content {
                    flex: 1;
                    .content-title-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        height: 48px;
                        background: linear-gradient(180deg, #FFFFFF 10.87%, #FBFBFB 110.55%);
                        padding: 0 24px;
                        .title-text {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #2F3438;
                            .number-text {
                                margin-left: 8px;
                                font-size: 12px;
                                line-height: 18px;
                                color: rgba(47, 52, 56, .5);
                            }
                        }
                        .link-right {
                            font-size: 14px;
                            line-height: 20px;
                            color: #C48B3A;
                            .icon-right {
                                margin-left: 8px;
                            }
                        }
                    }
                    .number-info-box {
                        &.first-line {
                            border-bottom: 1px solid rgba(47, 52, 56, .1);
                        }
                        .info-row {
                            padding: 24px 0 16px 24px;
                            cursor: pointer;
                            &.product-box {
                                height: 186px;
                            }
                            &.service-box {
                                height: 164px;
                            }
                            &:first-child {
                                border-right: 1px solid rgba(47, 52, 56, .1);
                            }
                            .title {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2F3438;
                                margin-bottom: 16px;
                            }
                            .number-content {
                                .amount {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    width: 140px;
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 22px;
                                    color: #C48B3A;
                                }
                                .number-text {
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 22px;
                                    color: #2F3438;
                                }
                                .desc {
                                    font-size: 14px;
                                    line-height: 22px;
                                    color: rgba(47, 52, 56, .6);
                                    text-align: left;
                                }
                            }
                            .browse-box {
                                font-size: 12px;
                                line-height: 22px;
                                color: rgba(47, 52, 56, .5);
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                margin-top: 20px;
                                .browse-item {
                                    &:first-child {
                                        margin-right: 26px;
                                    }
                                }
                            }
                        }
                    }
                }
                &.corporate-services {
                    margin-top: 24px;
                    .aside-info {
                        height: 376px;
                    }
                    .number-info-box {
                        .info-row {
                            padding: 24px;
                            &:last-child {
                                //border: 0;
                            }
                            .number-content {
                                .title {
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #2F3438;
                                    margin-bottom: 4px;
                                }
                                .desc {
                                    font-size: 14px;
                                    line-height: 28px;
                                    color: rgba(47, 52, 56, .6);
                                    display: flex;
                                    .amount {
                                        font-weight: 500;
                                        font-size: 18px;
                                        line-height: 28px;
                                        color: #C48B3A;
                                        margin-left: 16px;
                                        width: 170px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .platform-introduction-box {
        background: linear-gradient(180deg, #F8F8F8 0%, #F6F6F7 102.4%);
        padding-bottom: 80px;
        .title-box {
            height: 309px;
            background: url('../../resource/images/index/img-introduction-bg.svg') no-repeat center center;
            background-size: 100% 100%;
            text-align: center;
            .title {
                font-weight: 800;
                font-size: 32px;
                line-height: 42px;
                color: #FFFFFF;
                padding-top: 85px;
            }
            .desc {
                font-size: 20px;
                line-height: 42px;
                text-align: center;
                color: rgba(255, 255, 255, 0.8);
            }
        }
        .platform-introduction {
            margin-top: -144px;
            text-align: center;
            .desc-box {
                background: #FFF;
                padding: 20px 50px 37px;
                margin-top: 40px;
                text-align: left;
                overflow: hidden;
                display: flex;
                .aside-img {
                    margin-right: 110px;
                }
                .platform-desc {
                    max-width: 900px;
                    font-size: 16px;
                    line-height: 28px;
                    color: rgba(47, 52, 56, .8);
                    padding-top: 46px;
                }
            }
        }
    }
    .cooperative-agency {
        text-align: center;
        padding-top: 80px;
        background: #FFF;
        .cooperative-agency-content {
            .cooperative-logo {
                padding: 82px 0 112px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                .icon-bank {
                    width: 218px;
                }
                &.cooperative-logo-first {
                    padding-bottom: 0
                }
                &:last-child {
                    padding-top: 0;
                }
            }
        }
    }
    .boost-container {
        background: url('../../resource/images/index/img-introduction-bg-2.svg') no-repeat center center, url('../../resource/images/index/img-introduction-bg-1.svg') no-repeat center center;
        background-size: 100% 100%;
        .boost-content {
            padding: 83px 0;
            text-align: center;
            .title {
                font-weight: 500;
                font-size: 28px;
                line-height: 42px;
                color: #FFFFFF;
            }
            .desc {
                font-size: 22px;
                line-height: 42px;
                text-align: center;
                color: rgba(255, 255, 255, 0.8);
            }
            .start-btn {
                display: block;
                width: 148px;
                height: 40px;
                background: #FFFFFF;
                border-radius: 4px;
                text-align: center;
                cursor: pointer;
                margin: 51px auto 0;
                line-height: 40px;
                font-weight: 500;
                font-size: 16px;
                color: #C78C37;
            }
        }
    }
    .contact-us-container {
        background: url('../../resource/images/index/img-contact-bg.svg') no-repeat left center;
        background-size: 100% 100%;
        background-color: #FFF;
        .contact-content {
            height: 346px;
            padding-top: 110px;
            .footer-item {
                .title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 28px;
                    color: #2F3438;
                    margin-bottom: 24px;
                }
                .desc {
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(47, 52, 56, .5);
                    text-align: left;
                    margin-bottom: 16px;
                    display: block;
                    .title-icon {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
