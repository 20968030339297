.register-type-container {
    width: 100%;
    background: url('../../resource/images/login/login-bg.svg') no-repeat center bottom/100% auto, linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%);
    padding-top: 1px;
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
    .register-type-content {
        height: 640px;
        background: #FFF;
        text-align: center;
        padding-top: 48px;
        .title {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #2F3438;
        }
        .desc {
            font-size: 14px;
            line-height: 21px;
            color: rgba(47, 52, 56, .6);
            margin-top: 8px;
        }
        .type-box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 48px;
            .type-item {
                border: 0.5px solid rgba(47, 52, 56, 0.1);
                border-radius: 2px;
                width: 340px;
                height: 332px;
                padding: 30px 50px;
                display: block;
                &:first-child {
                    margin-right: 64px;
                }
                .img-type {
                    width: 240px;
                }
                .item-title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 28px;
                    color: #000000;
                    margin-top: 28px;
                }
                .item-desc {
                    font-size: 14px;
                    line-height: 21px;
                    color: rgba(47, 52, 56, .6);
                    margin-top: 4px;
                }
            }
        }
        .tip-text {
            font-size: 12px;
            line-height: 22px;
            text-align: center;
            color: rgba(47, 52, 56, .6);
            margin-top: 77px;
            span {
                color: #ddb98d;
            }
        }
    }
}