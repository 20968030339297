.detail-container {
    padding-top: 40px;
    padding-bottom: 50px;
    .breadcrumb {
        .ant-breadcrumb {
            margin: 50px auto 24px;
        }
    }
    .detail-info {
        padding: 24px;
        background: #FFFFFF;
        .img-box {
            width: 200px;
            height: 150px;
            border: 1px solid #EAEBEB;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .info-box {
            padding-left:40px;
            .info-text {
                margin-bottom: 24px;
                display: flex
            }
            .info-img {
                display: flex;
                align-items: flex-start;
            }
            .info-line {
                height: 1px;
                background: rgba(47, 52, 56, 0.1);
                transform: matrix(1, 0, 0, -1, 0, 0);
            }
            .title {
                font-weight: 500;
                font-size: 24px;
                color: #2F3438;
                margin-bottom: 40px;
            }
            .name {
                font-weight: 500;
                font-size: 24px;
                color: #2F3438;
                margin-bottom: 12px;
            }
            .website {
                font-size: 14px;
                color: rgba(47, 52, 56, 0.65);
                margin-bottom: 24px;
                .website-img {
                    margin-right: 9px;
                }
            }
            .business-title, .diff-title {
                font-size: 14px;
                color: #2F3438;
                display: inline-block;
                width: 126px;
            }
            .diff-range {
                font-size: 14px;
                padding-left: 24px;
                color: #2F3438;
            }
            .business-title {
                color: rgba(47, 52, 56, 0.6);
            }
            .diff-info {
                font-size: 14px;
                padding-left: 24px;
                color: rgba(47, 52, 56, 0.65);
            }
            .diff-range {
                flex: 1;
                line-height: 22px;
            }
            .license-img {
                display: inline-block;
                width: 200px;
                height: 150px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .main-business {
                padding: 16px 80px 16px 16px;
                background: #F8F8F8;
            }
        }
    }
}
