.firm-register-container.firm-replenish-container {
    padding-top: 40px;
    .firm-register-content {
        background: #FFF;
        .ant-steps {
            height: 90px;
            padding: 0 182px;
            align-items: center;
            border-bottom: 1px solid rgba(47, 52, 56, 0.1);
        }
        .steps-content {
            border-radius: 2px;
            background-color: #fafafa;
            text-align: center;
            &.is-show-content {
                display: none;
            }
            .info-tip-container {
                .info-tip-content {
                    margin-top: 0;
                }
            }

        }

        .steps-action {
            margin-top: 24px;
            margin-left: 198px;
            margin-bottom: 56px;
            padding-bottom: 48px;
            .submit-replenish {
                width: 74px;
            }
            .pre-btn {
                margin: 0 8px;
                background: #FFF;
                color: #C48B3A;
                border: none;
            }
        }
    }
    .info-tip-container {
        background: url('../../resource/images/login/login-bg.svg') no-repeat center bottom/100% auto, linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%);
        display: block;
        min-height: 100%;
        .info-tip-content {
            background: #FFF;
            text-align: center;
            height: 600px;
        }
    }
}