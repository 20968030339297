.product-detail-container {
    padding-top: 40px;
    padding-bottom: 50px;
    .breadcrumb {
        .ant-breadcrumb {
            margin: 50px auto 24px;
        }
    }
    .detail-info {
        padding: 24px;
        background: #FFFFFF;
        margin-bottom: 24px;
        .img-box {
            width: 200px;
            height: 150px;
            border: 1px solid #EAEBEB;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .fin-name {
            display: flex;
            align-items: flex-start;
            margin-right: 40px;
            img {
                margin-top:3px;
            }
        }
        .hot-rate {
            display: flex;
            line-height: 28px;
        }
        .info-box {
            padding-left:40px;
            .info-text {
                margin-bottom: 24px;
                display: flex
            }
            .info-img {
                display: flex;
                align-items: flex-start;
            }
            .info-line {
                height: 1px;
                background: rgba(47, 52, 56, 0.1);
                transform: matrix(1, 0, 0, -1, 0, 0);
            }
            .title {
                font-weight: 500;
                font-size: 24px;
                color: #2F3438;
                margin-bottom: 12px;
            }
            .name {
                font-weight: 500;
                font-size: 24px;
                color: #2F3438;
                margin-bottom: 12px;
            }
            .website {
                font-size: 14px;
                color: rgba(47, 52, 56, 0.65);
                margin-bottom: 24px;
                .website-img {
                    margin-right: 9px;
                }
            }
            .business-title, .diff-title {
                font-size: 14px;
                color: #2F3438;
                display: inline-block;
                width: 126px;
            }
            .diff-range {
                font-size: 14px;
                padding-left: 24px;
                color: #2F3438;
            }
            .business-title {
                color: rgba(47, 52, 56, 0.6);
            }
            .diff-info {
                font-size: 14px;
                padding-left: 24px;
                color: rgba(47, 52, 56, 0.65);
            }fz
            .diff-range {
                flex: 1;
                line-height: 22px;
            }
            .license-img {
                display: inline-block;
                width: 200px;
                height: 150px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .main-business {
                padding: 16px 80px 16px 16px;
                background: #F8F8F8;
            }
        }
        .bond-info {
            display: flex;
            align-items: flex-start;
            margin-bottom: 8px;
            color: #2F3438;
            font-size: 14px;
            opacity: 0.6;
            line-height: 22px;
        }
        .vector-img {
            margin-right: 8px;
        }
        .service{
            padding: 16px;
            margin-bottom: 14px;
            margin-top: 20px;
            background: #F8F8F8;
            font-size: 14px;
            color: #2F3438;
            .area {
                color: rgba(47, 52, 56, 0.6);
                display: inline-block;
                width: 70px;
            }
        }
        .range-info {
            display: flex;
            align-items:flex-end;
            .title-diff{
                font-size: 14px;
                color: #2F3438;
                opacity: 0.6;
                margin-top: 2px;
            }
            .title-num {
               // height: 36px;
                font-size: 14px;
                color: #2F3438;
                display:flex;
                align-items:flex-end;
            }
            .title-rate {
              //  height: 36px;
                font-size: 24px;
                color: #EB0000;
            }
        }
        .range-service {
            margin-top: 32px;
            align-items: center;
        }
        .range-service-col {
            border-left: 1px solid rgba(47,52,56,.1);
            height: 32px;
        }
        .recommend {
            display: flex;
            align-items: center;
        }
        .other-product {
            font-size: 16px;
            font-weight: 500;
            color: #2F3438;
            margin-left: 18px;
        }
        .other-product-img {
            width: 160px;
            height: 120px;
            margin-top: 24px;
            border: 1px solid #EAEBEB;
            box-sizing: border-box;
            border-radius: 2px;
            text-align: center;
            padding:2px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .other-product-title {
            font-size: 16px;
            font-weight: 500;
            color: #2F3438;
            margin: 12px 0 4px;
        }
        .rate-range {
            font-size: 18px;
            color: #EB0000;
        }
        .rate-title {
            font-size: 14px;
            opacity: 0.6;
        }
    }
    .part-one {
        margin-right: 24px;
    }
    .product-info {
        background: #FFFFFF;
        margin-bottom: 50px;
        padding: 28px 24px;
        .product-title {
            font-size: 16px;
            line-height: 25px;
            font-weight: 500;
            color: #2F3438;
            margin-left: 12px;
        }
        .product-img {
            display: flex;
            align-items: center;
            margin-top: 32px;
        }
        .features-one {
            margin-top: 16px;
            margin-left: 26px;
        }
        .time-limit {
            font-size: 14px;
            color: #2F3438;
        }
        .need-data {
            font-size: 14px;
            color: #2F3438;
            opacity: 0.6;
            padding-left: 2px;
        }
        .time-text {
            font-size: 14px;
            color: #2F3438;
            opacity: 0.6;
            margin-top: 8px;
        }
        .service-introduction {
            font-size: 14px;
            color: #2F3438;
            opacity: 0.6;
            padding-left: 25px;
            margin-top: 8px;
        }
    }
}
