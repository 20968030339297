.my-message-container {
    background: #FFFFFF;
    margin-bottom: 44px;
    padding: 0 32px 32px;

    .all-read-text {
        padding: 24px 0;
        color: #C38B3A;
        cursor: pointer;
    }


    .detail-breadcrumb {
        left: -286px;
    }

    .list-title-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 17px 58px 17px 24px;
        width: 100%;
        background-color: #F7F7F7;
        color: #2F3438;
    }

    .item-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 58px 20px 10px;
        border-bottom: 1px solid rgba(47, 52, 56, 0.1);

        &.one-page-end {
            border-bottom: none;
        }
    }

    .item-left {
        display: flex;
    }

    .time-text {
        margin-top: 8px;
        color: rgba(47, 52, 56, 0.45);
    }

    .open-link {
        flex-shrink: 0;
        color: #C38B3A;
        cursor: pointer;
    }

    .pagination-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        color: rgba(47, 52, 56, 0.6);
    }

    .pagination-content {
        text-align: right;

        li {
            width: 32px;
            height: 32px;
        }

        .ant-pagination-item:hover {
            border-color: #C48B3A;

            a {
                color: #C48B3A;
            }
        }

        .ant-pagination-item-active {
            background: #C48B3A;
            border-color: #C48B3A;

            &:hover {
                a {
                    color: #FFFFFF;
                }
            }

            a {
                color: #FFFFFF;
            }
        }
    }

    .item-desc {
        margin-right: 20px;
    }

    .no-data-img {
        display: block;
        margin: 0 auto;
        padding-top: 50px;
    }
}
