.firm-register-container {
    background: url('../../resource/images/login/login-bg.svg') no-repeat center bottom/100% auto, linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%);
    &.firm-register {
        min-height: calc(100vh - 120px);
    }
    .firm-register-content {
        .title-box {
            padding: 32px 0;
            .title {
                display: flex;
                font-weight: 500;
                font-size: 24px;
                line-height: 24px;
                color: #2F3438;
                align-items: center;
                width: 200px;
                .icon-back {
                    margin-right: 24px;
                }
            }
        }
        .form-content {
            background: #FFF;
            padding: 40px;
            margin-bottom: 48px;
            .left-from {
                padding-right: 40px;
            }
            .confirm-input {
                border-bottom: 1px solid rgba(47, 52, 56, 0.1);
            }
            .input-password {
                .ant-form-item-control-input {
                    width: 360px;
                }
            }
            .input-phone {
                margin-top: 32px;
                .ant-form-item-control-input {
                    width: 264px;
                }
            }
            .register-code {
                .ant-form-item-control-input {
                    width: 156px;
                }
                .img-code {
                    width: 94px;
                    height: 32px;
                    display: block;
                    cursor: pointer;
                }
            }
            .agreement {
                padding-top: 16px;
                margin-left: 102px;
                .ant-form-item-control-input {
                }
                .agreement-box {
                    line-height: 16px;
                    span, a {
                        line-height: 16px;
                    }
                }
                .agreement-text {
                    margin-right: 8px;
                }
            }
            .submit-btn {
                .ant-form-item-control-input {
                    margin-left: 102px;
                    .ant-btn {
                        width: 72px;
                    }
                }
            }
        }
        .desc-col {
            padding: 0 0 40px 40px;
            border-left: 1px solid rgba(47, 52, 56, 0.1);
            .desc-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #2F3438;
                padding-left: 12px;
                border-left: 4px solid #C38B3A;
            }
            .desc-box {
                padding-top: 22px;
                .item-text {
                    margin-bottom: 16px;
                    font-size: 14px;
                    line-height: 22px;
                    color: rgba(47, 52, 56, .6);
                    .number-text {
                        color: #ddb98d;
                    }
                }
            }
        }
    }
}