.agency-detail-transaction {
    .distribute {
        width: 80px;
        height: 32px;
        background: #C38B3A;
        border-radius: 2px;
        color:#FFFFFF;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
    }
    .transaction-validity {
        margin-bottom: 15px;
    }
    .legal-name {
        margin-left:  200px;
    }
    .list-content {
        &:nth-last-child(1) {
            margin-bottom: 40px;
        }
        .secured-box {
            .ant-checkbox-group-item {
                display: flex;
                margin-bottom: 18px;
                padding-left: 10px;
            }
        }
        .secured-input {
            cursor: pointer;
        }
        .list-item {
            width: 100%;
            height: 56px;
            line-height: 56px;
            border: 1px solid rgba(47, 52, 56, 0.1);
            border-top:0;
            box-sizing: border-box;
            .text {
                font-size: 14px;
                color: rgba(47, 52, 56, 0.65);;
                padding: 0px 14px;
                .edit-box {
                    display: flex;
                    margin-top: 13px;
                    .ant-select-item-option-state {
                        display: none;
                    }
                }
                .secured-box {
                    margin-top: 13px;
                    width: 120px;
                    .secured-input {
                        width: 120px;
                    }
                }
                .secured-input-box {
                    position: absolute;
                    top: 50px;
                }
                .secured-select-box {
                    width: 120px;
                    padding-top: 15px;
                    background: #FFFFFF;
                    border: 1px solid #D9D9D9;
                    .secured-btn {
                        display: flex;
                        justify-content: space-around;
                        border-top: 1px solid #D9D9D9;
                        height: 36px;
                        line-height: 36px;
                        .btn-cancel {
                            cursor: pointer;
                        }
                        .btn-ok {
                            cursor: pointer;
                            color: #C38B3A;
                        }
                    }
                }
                .input-flex {
                    margin-bottom: 5px;
                    position: absolute;
                    .ant-form-item-control-input-content {
                        display: flex;
                        input {
                            width: 120px;
                        }
                    }
                    .ant-select-selector {
                        width: 120px;
                    }
                    .ant-input-number  {
                        width: 120px;
                        .ant-input-number-handler-wrap {
                            display:none
                        }
                    }
                }
                .unit-text {
                    font-size: 14px;
                    color: rgba(47, 52, 56, 0.65);
                    line-height: 32px;
                    margin-left: 8px;
                    position:absolute;
                    left: 140px;
                }
                .item-height {
                    height: 40px;
                    margin-bottom:25px;
                    .ant-checkbox + span {
                        color: rgba(47, 52, 56, 0.65);
                    }
                }
            }
            .edit-btn {
                margin-top: 7px;
                cursor: pointer;
                font-size: 14px;
                color:#C38B3A;
                background: none;
                &:hover {
                    background: none;
                }
            }
        }
    }
    .btn-operate {
        margin-top:35px;
        .btn {
            width: 74px;
            height: 32px;
            padding: 5px 23px;
            background: #C48B3A;
            border-radius: 2px;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            font-weight:400;
            cursor: pointer;
        }
        .btn-reject {
            margin-left: 20px;
            background: #FFFFFF;
            color: #C38B3A;
            border: 1px solid #C38B3A;
        }
    }
}

.transaction-list,.enterprise-info {
    .ant-row {
        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
    .enterprise-info-title {
        font-size: 14px;
        color: #2F3438;
        padding-left: 24px;
    }
    .enterprise-info-text {
        font-size:14px;
        color: rgba(47, 52, 56, 0.65);
        padding-left: 24px;
        .img-box {
            background: #F7F7F7;
            width: 192px;
            height: 120px;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 188px;
                max-height: 104px;
            }
        }
    }
}
.enterprise-info {
    .ant-row {
        margin-bottom: 24px;
    }
}
 .transaction-list {
     padding: 0 10px;
     .ant-form {
        //padding: 0 24px;
         div {
             &:nth-last-child(1) {
                 padding: 0;
             }
         }
     }
     .line {
         width: 100%;
         border-top: 1px solid rgba(47, 52, 56, 0.06);
         margin-bottom: 24px;
     }
     .input-flex {
         padding-left: 24px;
         .ant-form-item-control-input-content {
             display: flex;
             input {
                 width: 180px;
             }
         }
         .ant-input-number  {
             width: 180px;
             .ant-input-number-handler-wrap {
                 display:none
             }
         }
     }
     .unit-text {
         font-size: 14px;
         color: rgba(47, 52, 56, 0.65);
         line-height: 32px;
         margin-left: 8px;
     }
     .item-height {
         height: 40px;
         margin-bottom:25px;
         .ant-checkbox + span {
             color: rgba(47, 52, 56, 0.65);
         }
     }
      .footer-btn {
             text-align: right;
             margin-top: 24px;
             background: #FBFBFB;
             border-top: 1px solid rgba(47, 52, 56, 0.06);
             .ant-form-item-control-input {
                 max-width: 100%;
             }
             .submit-btn {
                 width: 74px;
                 margin-top: 15px;
                 &:nth-child(1) {
                     margin-right: 12px;
                     span {

                     }
                 }
                 &:nth-child(2) {
                     margin-right: 25px;
                 }
             }

             .cancel-btn {
                 border: 1px solid #C38B3A;
                 background: #ffffff;
                 span {
                     color: #C38B3A;
                 }
             }
         }
     .enterprise-info-header {
         margin-bottom: 24px;
     }
}
.modal-enterprise-modal {
    .ant-modal-footer {
        border-top: 1px solid rgba(47, 52, 56, 0.06);
        background:  #FBFBFB;
        height: 72px;
        padding-top: 20px;
        button {
            &:nth-child(1) {
                display:none;
            }
        }
    }
}
.modal-transaction-list {
    .ant-modal-body {
        padding: 24px 0;
    }
}
.select-box {
    .ant-select-dropdown-hidden {
        display: none;
    }
}
.shelved-page {
    .shelved-page-content {
        min-width: 1440px;
        max-width: 1440px;
        width: 100%;
        margin: 48px auto;
        background: #FFFFFF;
    }
    .shelved-img {
        margin: 0 auto 15px;
        padding-top: 90px;
        padding-bottom: 170px;
        text-align: center;
        img {
            width: 232px;
            height: 191px;
        }
        .shelved-title {
            font-size: 24px;
            color: #2F3438;
            padding-bottom: 12px;
        }
        .shelved-text {
            font-size: 16px;
            color: rgba(47,52,56,.6);
            padding-bottom: 32px;
        }
        .return-link {
            display: block;
            width: 88px;
            height: 32px;
            background: #C48B3A;
            border-radius: 2px;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;
            margin: 0 auto;
            &:hover {
                color: #FFF;
            }
        }
    }
}
