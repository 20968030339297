.footer-content {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.5px solid rgba(47, 52, 56, 0.1);
    font-size: 12px;
    line-height: 17px;
    color: rgba(47, 52, 56, .5);
    .text-link {
        color: rgba(47, 52, 56, .5);
        &:hover {
            color: #C48B3A;
        }
    }
    .icon-security {
        margin: 0 8px;
    }
    .link-item {
        color: rgba(47, 52, 56, .5);
        &:hover {
            color: #C48B3A;
        }
    }
}