.ant-layout {
    min-height: 100%;
    background: linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%);

}
.ant-layout-has-sider {
        //min-width: 1440px;
        //max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        .ant-layout-sider {
            background: #FFFFFF;
            min-height: 432px!important;
            padding-bottom: 8px;
        }
        .user-aside {
            .ant-menu {
                position: relative;
                background: #FFFFFF;
                margin-left: 25px;
            }
            .ant-menu-item-icon {
                margin-right: 16px;
            }
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background: #FFFFFF;
            border-left-color: #C38B3A;
        }
            //.ant-menu-submenu {
            //        .ant-menu-submenu-title {
            //            margin-bottom: 35px;
            //        }
            //}
            .ant-menu-submenu-title,.ant-menu-item a {
                color: rgba(47,52,56,.6);
            }
            .ant-menu-submenu-title span{
                margin-right: 16px;
            }
            .ant-menu-item-selected,.ant-menu-submenu-selected, .ant-menu-submenu-open,.ant-menu-submenu-active,.active a {
                color: #C38B3A;
                background:  #FFFFFF;
        }
            .ant-menu-item-active,.ant-menu-submenu-active {
               background:  #FFFFFF;
            }
             .ant-menu-submenu-title:hover {
                color: #C38B3A;
                 background:  #FFFFFF;
            }
            .ant-menu-item:hover,.ant-menu-item a:hover{
                color: #C38B3A;
            }
            .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
               width: 0px;
                height: 0px;
            }
            .ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
                padding-left: 25px !important;
            }
            .ant-menu-sub.ant-menu-inline {
                border: 0;
                border-left: 1px solid rgba(47, 52, 56, 0.1);
                margin-left: 12px;
            }
            .ant-menu-sub {
                li {
                &:nth-child(1) {
                }
                }
            }
            .ant-menu-submenu {
                margin-bottom: 32px;
            }
            .ant-menu-submenu-open{
                .ant-menu-submenu-title {
                    color:#C38B3A;
                    margin-bottom: 32px
                }
            }
             .ant-menu-item:not(:last-child) {
                margin-bottom: 32px;
            }
            .ant-menu-item, .ant-menu-submenu {
                .menu-icon {
                    margin-right: 16px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                }
                .menu-icon-active {
                    opacity: 0;
                }
                &.ant-menu-item-selected, &.ant-menu-item-active, &.ant-menu-submenu-active, &.ant-menu-submenu-selected, &.active-submenu,&.ant-menu-submenu-open {
                    .menu-icon-active {
                        opacity: 1;
                    }
                    .menu-icon-default {
                        opacity: 0;
                    }
                }
            }
            .ant-menu-inline .ant-menu-item {
                margin-top: 0;
            }
            .ant-menu-item-only-child {
                border-left: 1px solid  #ffffff;
                position: relative;
                left: -1px;
                overflow: hidden;
            }
             .ant-menu-item-only-child:hover{
                 border-left-color: #C38B3A;
            }
            .ant-menu-item-selected a{
                color: #C38B3A;
            }
            .default {
                border-left-color: rgba(47, 52, 56, 0.1);
            }
            .active {
                border-left-color: #C38B3A;
            }
            .active-submenu a{
                color: #C38B3A;
            }
            .zhigui-logo {
                min-height: 80px;
                margin: 0 auto 24px;
               // line-height: 80px;
                background: #C38B3A;
                color: #FFFFFF;
                font-size: 16px;
                text-align: center;
                padding: 10px 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                .zhigui-logo-text {
                    text-align: left;
                    display: inline-block;
                    word-wrap: break-word;
                    white-space: normal;
                    word-break: break-all;
                }
            }
            .ant-menu-submenu {
                .ant-menu-submenu-title {
                    //margin-bottom: 0px;
                    &::after {
                        position: absolute;
                        top: 0;
                        right: initial;
                        bottom: 0;
                        left: 0;
                        transform: scaleY(0.0001);
                        opacity: 0;
                        transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
                        content: '';
                    }
                }
                &.ant-menu-submenu-selected {
                    .ant-menu-submenu-title {
                        &::after {
                            transform: scaleY(1);
                            opacity: 1;
                            transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
                        }
                        .ant-menu-submenu-arrow {
                            &::before, &::after {
                                background: white;
                            }
                        }
                    }
                }
            }
    }
        .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
            border-right: 0;
        }
    .breadcrumb {
        height: 71px;
        line-height: 71px;
    }
     .ant-breadcrumb {
        //margin-top: 60px;
        height: 71px;
        line-height: 71px;
    }

    .badge {
        position: absolute;
        right: 48px;
        bottom: 44px;
        background: rgba(235, 0, 0, 0.76);
        border-radius: 10px;
        line-height: 16px;
        font-size: 12px;
        padding: 0 8px;
        height: 16px;
        color: #ffffff;
    }
}
