.account-info-container {
    background: #FFFFFF;
    padding: 0 32px 32px;
    .info-content {
        .item-type-box {
            padding-bottom: 24px;
            border-bottom: 1px solid rgba(47, 52, 56, 0.06);
            &:last-child {
                border-bottom: 0;
            }
            &.edit-box {
                padding-top: 32px;
            }
        }
        .upload-container .ant-upload.upload-box {
            height: 151px;
            width: 238px;
        }
        .upload-container .ant-upload.upload-box .upload-dialog .file-type .file-image {
            max-height: 151px;
            max-width: 236px;
        }
        .btn-box {
            margin-left: 150px;
            .submit-btn {
                margin-right: 12px;
                margin-top: 24px;
                width: 74px;
            }
            .close-btn {
                width: 74px;
                border: 1px solid #C38B3A;
                border-radius: 2px;
                color: rgba(195, 139, 58, 1);
            }
        }
        .ant-form-item-label {
            width: 150px;
        }
        .not-extra.date-time {
            margin-left: 150px;
        }

        .item-box {
            display: flex;
            align-items: flex-start;
            margin-top: 32px;
            &.edit-item {
                display: block;
            }
            .label-text {
                min-width: 126px;
                font-size: 14px;
                line-height: 22px;
                color: #2F3438;
                margin-right: 24px;
            }
            .desc {
                font-size: 14px;
                line-height: 22px;
                color: rgba(47, 52, 56, 0.65);
                img {
                    border: 1px solid rgba(47, 52, 56, 0.05);
                }
            }
            .edit-btn {
                font-size: 14px;
                line-height: 20px;
                color: #C38B3A;
                margin-left: auto;
                margin-right: 0;
                cursor: pointer;
                min-width: 45px;
                text-align: right;
            }
            .img-box {
                .img-bg {
                    padding: 4px 9px;
                    background: #F7F7F7;
                    border-radius: 2px;
                    text-align: center;
                    margin-right: 24px;
                    display: inline-block;
                    img {
                        max-width: 171px;
                        max-height: 112px;
                        vertical-align: middle;
                    }
                }
            }
        }
        textarea.ant-input {
            height: 162px;
        }
    }
}
.icon-waring {
    font-size: 16px;
    color: rgba(250, 173, 20, 1);
    margin-right: 16px;
    margin-left: 8px;
}
.account-modal-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2F3438;
}
.account-modal-desc {
    font-size: 14px;
    line-height: 24px;
    color: rgba(47, 52, 56, 0.4);
    margin-top: 4px;
    padding-left: 40px;
}
