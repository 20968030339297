.requirement{
    margin-left: 24px;
    min-height: 80vh;
    background: #FFFFFF;
    padding: 20px 32px 30px;
    margin-bottom: 50px;
    .requirement-index-container {
        margin-top: -71px;
        .title {
            font-size: 20px;
            color: #2F3438;
            display: inline-block;
            border-bottom: 1px solid #C38B3A;
            padding-bottom: 12px;
            position: relative;
            bottom: -1px;
        }
        .title-detail {
            font-size: 20px;
            color: #2F3438;
            display: inline-block;
            margin-bottom: 24px;
        }
        .apply-require-btn {
            width: 110px;
            background: #C48B3A;
            color: #FFFFFF;
            text-align: center;
            padding: 5px;
            cursor: pointer;
        }
        .company-manage {
            border-top: 1px solid rgba(47, 52, 56, 0.1);
        }
    }
    .ant-tabs-nav {
        margin-bottom:0px;
    }
    .ant-tabs-nav-list {
        .ant-tabs-tab {
            &:nth-child(1) {
                .tab-item {
                    padding-left: 10px;
                    border-left: 0;
                }
            }
        }
    }
    .tab-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        height: 16px;
        font-weight: normal;
        border-left: 1px solid rgba(47, 52, 56, 0.1);
        padding: 0 0 1px 30px;
        margin: 30px 30px 24px 0;
    }
    .ant-tabs-nav::before {
        border: 0;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #C38B3A;
        font-weight: normal;
    }
    .ant-tabs-ink-bar {
        background: #C38B3A;
    }
    .ant-tabs-tab {
        color: rgba(47, 52, 56, 0.6);
        margin-right: 0;
    }
    .ant-tabs-tab:hover {
        color: #C38B3A;
    }
    .ant-tabs-ink-bar-animated {
        display: none
    }
    .requirement-header-content {
        width: 100%;
        height: 56px;
        background: #F7F7F7;
        border: 1px solid rgba(47, 52, 56, 0.1);
        border-bottom: 0;
        box-sizing: border-box;
        .text {
            font-size: 14px;
            color: #2F3438;
            font-weight: 500;
            line-height: 56px;
            padding: 0px 14px;
        }
    }
    .list-header {
        display:  flex;
        justify-content: space-between;
        background: #F7F7F7;
        margin-top: 16px;
        height: 48px;
        line-height: 48px;
        padding: 0 24px;
        border: 1px solid #EEEEEE;
        align-items: center;
        .list-header-title {
            font-size: 14px;
            color: rgba(47, 52, 56, 0.5);
            .text {
                color:#2F3438;
            }
        }
        .requirement-num {
            margin-right: 50px;
        }
        .requirement-time {
            margin-right: 45px;
        }
        .requirement-validity {
            color: rgba(47, 52, 56, 0.5);
            img {
                margin-right:10px;
            }
        }
    }
    .list-row {
        border: 1px solid #EEEEEE;
    }
    .list-detail {
        display: flex;
        border-top: 0;
        font-size:14px;
        justify-content: space-between;
        padding: 25px;
        margin-right: 60px;
        .list-title {
            font-size: 14px;
            color: #2F3438;
            opacity: 0.6;
            margin: 0 12px 16px 0;
        }
        .amount {
            color: #EB0000;
        }
        .text {
            color:rgba(47, 52, 56, 1);
            font-size: 14px;
        }
        .other-more {
            margin-top: 16px;
        }
    }
    .del-box {
        border-left: 1px solid #EEEEEE;
        align-items: center;
        display: flex;
        justify-content: center;
        .del-btn {
           cursor: pointer;
            font-size: 14px;
            color:  #C38B3A;
        }
    }
}

.add-requirement {
    min-height: 80vh;
    background: #FFFFFF;
    padding: 40px;
    margin: 140px 144px 60px;
    .add-title {
        font-size: 24px;
        color: #2F3438;
        margin-top: -100px;
        margin-left: -34px;
    }
    .add-form {
        padding-top: 50px;
        textarea,span {
            color: rgba(47, 52, 56, 0.65)
        }
        textarea {
            width: 520px;
            height: 162px;
        }
    label {
        font-size: 14px;
        color:rgba(47, 52, 56, 0.85)
    }
    .to-text {
        font-size: 14px;
        color: rgba(47, 52, 56, 0.65);
        line-height:32px;
        margin-right: 15px;
        margin-left: 5px;
    }
    .unit-text {
        font-size: 14px;
        color: rgba(47, 52, 56, 0.65);
        line-height: 32px;
        margin-left: 8px;
    }
    .price-range {
        display: flex;

    }
    .item-height {
        height: 40px;
        margin-bottom:25px;
    }
    .contact-way {
        height: 70px;
        margin-bottom: 0;
    }
    .line {
        padding-top: 25px;
        border-top: 1px solid rgba(47, 52, 56, 0.06);;
    }
    .input-flex {
        .ant-form-item-control-input-content {
            display: flex;
            input {
                max-width: 264px;
            }
        }
    }
    .no-title {
        margin-left: 100px;
    }
    .ant-form-item-explain, .ant-form-item-extra {
        min-height: 24px;
        margin-top: 6px;
    }
    .footer-btn {
        margin-left: 110px;
        margin-top: 40px;
        background: #ffffff;
        height: 78px;
        .submit-btn {
            width: 74px;
            margin-top: 20px;
            &:nth-child(1) {
                margin-right: 12px;
                span {
                    color: #ffffff;
                }
            }
            &:nth-child(2) {
                margin-right: 25px;
            }
        }

        .cancel-btn {
            border: 1px solid #C38B3A;
            background: #ffffff;
            span {
                color: #C38B3A;
            }
        }
    }
    .select-type {
        width:345px;
    }
    .upload-box {
        width: 240px;
    }
        .ant-row {
            max-width: 80%;
        }
    .ant-input-number  {
        width: 180px;
        .ant-input-number-handler-wrap {
            display:none
        }
        .ant-input-number-input-wrap {
            height: 32px;
            line-height: 32px;
        }
    }
    }
    //.requirement-content {
    //    margin: 140px 144px 60px;
    //}
}

