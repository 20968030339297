.product-supermarket-container {
    .product-banner {
        margin-bottom: 24px;
        margin-top:60px;
        img {
            width: 100%;
        }
    }
        .tab-item {
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            line-height: 30px;
            font-weight: normal;
        }
    .ant-tabs-nav::before {
        border: 0;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #C38B3A;
        font-weight: normal;
    }
    .ant-tabs-ink-bar {
        background: #C38B3A;
    }
    .ant-tabs-tab {
        color: rgba(47, 52, 56, 0.6);
    }
    .ant-tabs-tab:hover {
        color: #C38B3A;
    }
    .range-content {
    }
    ul{
        position: relative;
        li{
            float: left;
            width: 150px;
            span{
                font-size: 14px;
                color: #2F3033;
                border-radius: 4px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                padding: 0 10px;
                display: inline-block;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .active{
            span{
                background: #024AA0;
                color: #fff;
            }
        }
    }
    .range{
        display: flex;
        line-height: 70px;
        .range-wd{
            display: inline-block;
            padding: 0 20px;
            text-align: center;
            font-size: 14px;
            color: #2F3033;
            vertical-align: top;
        }
        .ant-form-item {
           line-height: 70px;
        }
    }
    .item-market {
        height: 70px;
        line-height: 70px;
        display: flex;
        .ant-form-item-explain {
            margin-top: -17px;
            font-size:8px;
        }
        .item-title {
            width: 75px;
            margin-right: 24px;
        }
        .item-input {
            width: 224px;
        }
        .item-select {
            margin-top: 20px;
            width: 500px;
            .ant-select {
                margin-right: 16px;
                width: 226px;
            }
            .ant-select-selector {
              width: 226px;
            }
            .ant-form-item-control-input-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
   .range-btn {
        border:none;
        font-size: 14px;
        color: #2F3438;
        opacity: 0.8;
        &:hover{
            cursor: pointer;
            background: #C48B3A;
            border-radius: 2px;
            color: #FFFFFF;
        }
    }
    .ant-radio-inner {}
    .ant-radio {
        border:none;
        font-size: 14px;
        color: #2F3438;
        opacity: 0.8;
    }
    .active-select {
        cursor: pointer;
        background: #C48B3A;
        border-radius: 2px;
        color: #FFFFFF;
    }
    .debt {
        background: #FFF;
        padding: 24px 32px;
        margin-bottom: 24px;
        margin-top: 10px;
        .debtRow {
            border-bottom: 1px dashed #E9E9E9;
            &:nth-child(3) {
                border:0;
            }
        }
    }
    .total-data {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #2F3438;
        margin-bottom: 24px;
        .total-num {
            color:  #C48B3A;
        }
    }
    .table-data {
        padding: 24px;
        background: #FFF;
        margin-bottom: 24px;
    }
    .product-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img-detail {
            width: 160px;
            height: 120px;
            border: 1px solid #EAEBEB;
            border-radius: 2px;
            text-align: center;
        }
        .range-info {
            display: flex;
            .ant-row {
                display: flex;
                align-items: flex-end;
                .ant-col {
                }
            }
            .title-diff{
                font-size: 14px;
                color: #2F3438;
                opacity: 0.6;
                margin-top: 2px;
            }
            .title-num {
                //height: 36px;
                font-size: 14px;
                color: #2F3438;
                display:flex;
                align-items:flex-end;
            }
           .line {
               border-left: 1px solid rgba(47,52,56,.1);
               padding-left: 20px;
               height: 36px;
           }
            .title-rate {
                //height: 36px;
                font-size: 24px;
                color: #EB0000;
            }
        }
        .detail {
            padding-left: 48px;
            border-right: 1px solid rgba(47, 52, 56, 0.1);
        }
    }
    .pageStyle {
        float: right;
        margin-bottom: 40px;
        li {
            width: 32px;
            height: 32px;
        }
        .ant-pagination-item:hover {
            border-color: #C48B3A;
            a {
                color: #C48B3A;
            }
        }
        .ant-pagination-item-active {
            background: #C48B3A;
            border-color: #C48B3A;
            &:hover {
                a {
                    color: #FFFFFF;
                }
            }
            a {
                color: #FFFFFF;
            }
        }
    }
    .title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        font-weight: 500;
        color: #2F3438;
    }
    .address {
        margin-bottom: 8px;
        color: #2F3438;
        font-size: 14px;
        opacity: 0.6;
        display: flex;
        align-items: center;
        line-height: 22px;
    }
    .vector-img {
        margin-right: 8px;
    }
    .detail-btn {
        width: 92px;
        height: 32px;
        padding: 5px 10px;
        background: #C48B3A;
        margin-left: 56px;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        font-weight:400;
        cursor: pointer;
    }
    .look-detail {
        width: 92px;
        height: 32px;
        padding: 5px 10px;
        border: 1px solid #C48B3A;
        margin-left: 56px;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #C48B3A;
        font-weight:400;
        cursor: pointer;
    }
    .ant-input-number {
       width: 150px;
    }
    .ant-pagination-options {
        display:none;
    }
    .company-name {
        margin-bottom: 38px;
        color: #2F3438;
        font-size: 14px;
        opacity: 0.6;
        display: flex;
        align-items: center;
        line-height: 22px;
    }
    .price-info {
        line-height: 40px;
        .price-range, .service-type {
            display: flex;
            align-items: center;
            .title-diff{
                font-size: 14px;
                color: #2F3438;
                opacity: 0.6;
                margin-right: 12px;
            }
            .title-num {
                font-size: 14px;
                color: #2F3438;
                display:flex;
                align-items:flex-end;
            }
            .title-rate {
                font-size: 24px;
                color: #EB0000;
            }
        }
        .price-range {
            border-right: 1px solid rgba(47,52,56,.1);
            margin-right: 24px;

        }
    }
    .img-detail {
        width: 160px;
        height: 120px;
        border: 1px solid #EAEBEB;
        border-radius: 2px;
        text-align: center;
        padding:2px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .search-btn {
        margin-left:45px;
    }
    .no-data-container {
        margin-bottom: 40px;
    }
}
