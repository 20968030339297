#root, .body-container {
    min-height: 100%;
}
.content {
    min-width: 1440px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
}
@media screen and (max-width: 1500px) {
    .content {
        min-width: 1152px;
        max-width: 1152px;
        width: 100%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 575px) {
    #root {
        .form-content {
            .ant-form-item {
                height: auto;
                &.not-extra {
                    height: auto;
                }
            }
            .retrieve-btn {
                margin-top: 24px;
            }
        }
    }
}

.container {
    min-width: 1152px;
    width: 100%;
}
a {
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    color: #C48B3A;
    &:hover {
        color: #C48B3A;
    }
}
input::-webkit-input-placeholder {
    color: rgba(47, 52, 56, .3);
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: rgba(47, 52, 56, .3);
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
}
input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: rgba(47, 52, 56, .3);
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
}
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(47, 52, 56, .3);
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
}
// input 输入框hover效果
.ant-input-number:hover,.ant-input:hover {
    border-color: #C48B3A;
}
.ant-form-item-explain, .ant-form-item-extra {
    font-size: 12px;
    margin-top: 2px;
}
.ant-form-item-extra {
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
    word-break: break-all;
}
.ant-form-item-explain, .ant-form-item-extra {
    min-height: initial;
}
.ant-layout-footer {
    padding: 0;
    background: transparent;
}
.ant-layout {
    background: #FFF;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}
.ant-form-item-label > label::after {
    display: none;
}
.ant-form-item-label {
    width: 102px;
    text-align: left;
}
.ant-form-item-control-input {
    width: 100%;
    max-width: 440px;
}
.input-tip-desc {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.25);
    margin-top: 6px;
}
.form-content {
    .ant-form-item {
        height: 88px;
        margin-bottom: 0;
        &.not-extra {
            height: 64px;
        }
        .ant-input, .ant-input-password {
        }
        &.upload-item {
            height: auto;
            &.height-item {
                .ant-form-item-control {
                    height: 250px;
                    max-width: 240px;
                }
            }
            .ant-form-item-control {
                height: 230px;
                max-width: 240px;
            }
            .ant-form-item-control-input {
                width: 240px;
            }
        }
        &.desc-input {
            height: auto;
            .ant-form-item-control-input {
                .ant-input {
                    max-width: 520px;
                    height: 162px;
                }
            }
        }
        &.create-time-item {
            .ant-picker {
                width: 200px;
            }
        }
    }
}
.ant-upload-list {
    //display: none; // 隐藏文件list
}
.number-code {
    position: relative;
    .ant-input-suffix {
        height: 32px;
        position: absolute;
        right: -103px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        overflow: hidden;
        text-align: left;
        margin-left: 0;
        display: block;
    }
}
// 修改公共hover效果
.ant-input-affix-wrapper:hover {
    border-color: #C48B3A;
}
.ant-input-number:hover,.ant-input:hover {
    border-color: #C48B3A;
}
.ant-input-number-affix-wrapper:focus,.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused,.ant-input-number-affix-wrapper-focused {
    border-color: #C48B3A;
    box-shadow: 0 0 0 2px rgba(195, 139, 58, .2);
}
.ant-input-number:focus,.ant-input:focus, .ant-input-focused, .ant-input-number-focused{
    border-color: #C48B3A;
    box-shadow: 0 0 0 2px rgba(195, 139, 58, .2);
}
.ant-btn-primary {
    background: #C48B3A;
    border-color: #C48B3A;
}
.ant-btn-primary:active {
    background: #C48B3A;
    border-color: #C48B3A;
    box-shadow:none;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
    background: #C48B3A;
    border-color: #C48B3A;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #C38B3A;
    border-color: #C38B3A;
}
.ant-steps-item-finish .ant-steps-item-icon {
    border-color: #C38B3A;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #C38B3A;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #C38B3A;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #C38B3A;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #C48B3A;
    box-shadow: 0 0 0 2px rgba(195, 139, 58, .2);
}
.ant-picker-today-btn {
    color: #C38B3A;
}
.ant-cascader-menu-item-expand {
    width: 100%;
}
.ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
    border-color: #C48B3A;
    border-right-width: 0;
}
.ant-cascader-picker:focus .ant-cascader-input {
    border-color: #C48B3A;
    box-shadow: 0 0 0 2px rgba(195, 139, 58, .2);
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: 600;
    background: rgba(195, 139, 58, .2)
}
.ant-picker-focused {
    border-color: #C48B3A;
    box-shadow: 0 0 0 2px rgba(195, 139, 58, .2);
}
.ant-picker:hover, .ant-picker-focused {
    border-color: #C48B3A;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #C48B3A;
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #C48B3A;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #C48B3A;
}
.ant-radio-checked::after {
    border-color: #C48B3A;
}
.ant-radio-inner::after {
    background-color: #C48B3A;
}
.ant-picker-range .ant-picker-active-bar {
    background: #C48B3A;
}
.ant-btn:hover, .ant-btn:focus {
    //color: #C48B3A;
    //border-color: #C48B3A;
}
.ant-checkbox-checked .ant-checkbox-inner {
    border-color: #C48B3A;
    background: #C48B3A;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #C48B3A;
}
.ant-checkbox-checked::after {
    border-color: #C48B3A;
}
.ant-form-item-has-error.input-password {
    .ant-form-item-extra {
        display: none;
    }
}

//公共页码样式复写
.page-style {
    margin-top: 32px;
    text-align: right;
    .ant-pagination-item-active a {
        color: #C48B3A;
    }
    .ant-pagination-item-active {
        border-color: #C48B3A;
    }
    .ant-pagination-item:focus, .ant-pagination-item:hover {
        border-color: #C48B3A;
        a {
            color: #C48B3A;
        }
    }
    .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
        border-color: #C48B3A;
        color: #C48B3A;
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: #C48B3A;
    }
}

.ant-layout-has-sider {
    background: #F8F8F8;
    .ant-layout-content {
        margin-left: 24px;
        margin-top: 71px;
    }
}
.profile-container {
    background: #F7F7F7;
    .index-header {
        position: static!important; //个人中心header不定位
    }
}

.profile-crumb {
    margin-top: 24px;
    margin-bottom: 24px;
}

// 将新闻列表提出放在公共，因为个人中心有一样的
.news-list-box {
    padding: 16px 32px;
    background: #FFF;
    .list-item {
        padding: 16px 0;
        border-bottom: 1px solid rgba(47, 52, 56, .1);
        display: block;
        &:last-child {
            border-bottom: 0;
        }
        .news-title {
            font-size: 16px;
            line-height: 22px;
            color: #2F3438;
            margin-bottom: 12px;
            padding-left: 26px;
            background: url('../../resource/images/public/icon-news-title.svg') no-repeat left center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .news-desc {
            font-size: 14px;
            line-height: 24px;
            color: rgba(47, 52, 56, 0.6);
            margin-bottom: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -moz-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            box-orient: vertical;
            &.w-e-text {
                display: inline;
                * {
                    &::after {
                        content: '\A';
                        white-space: pre;
                    }
                }
            }
        }
        .w-e-text p, .w-e-text h1, .w-e-text h2, .w-e-text h3, .w-e-text h4, .w-e-text h5, .w-e-text table, .w-e-text pre {
            margin: 0;
        }
        .news-time {
            line-height: 18px;
            color: rgba(38, 40, 44, 0.45);
            .tag {
                display: inline-block;
                height: 18px;
                padding: 0 8px;
                background: #FFF8E1;
                border-radius: 2px;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                color: #94A1B8;
                margin-right: 12px;
            }
        }
    }
}

// 个人中心公共header样式提取
.profile-title-box {
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(47, 52, 56, 0.1);
    .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 62px;
        color: #2F3438;
        border-bottom: 1px solid #C38B3A;
    }
    .update-time {
        font-size: 14px;
        line-height: 24px;
        color: rgba(47, 52, 56, 0.4);
    }
}

//弹窗统一样式按钮调整
.ant-modal-footer {
    border-top: 0;
    .ant-btn {
        width: 74px;
        height: 32px;
        border: 1px solid #C38B3A;
        color: rgba(195, 139, 58, 1);
        &.ant-btn-primary {
            color: #FFFFFF;
        }
    }
}
.news-code-input {
    .ant-form-item-control-input {
        width: 156px;
        .send-code-btn {
            width: 94px;
            height: 32px;
            background: #C48B3A;
            border-radius: 2px;
            font-size: 14px;
            line-height: 32px;
            color: #FFFFFF;
            text-align: center;
            cursor: pointer;
            &.number-btn {
                background: rgba(47, 52, 56, .6)
            }
        }
    }
}
.set-padding {
    .ant-modal-content {
        padding-bottom: 14px;
        .logout-title {
            padding-bottom: 30px;
        }
    }
}
.detail-breadcrumb {
    position: relative;
    top: -91px;
    left:-310px;
}
.breadcrumb-content {
    margin-left: 24px;
    padding: 20px 32px;
}
.detail-breadcrumb-margin {
    margin-top: -71px;
}
.ant-breadcrumb-link {
    color:#2F3438;
    a {
        &:hover {
            color: #C38B3A;
        }
    }
}

.page-total-data {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    color: rgba(47, 52, 56, 0.6);
    font-size: 14px;
    height: 80px;
    line-height: 80px;
    .total-num {
        color: #C38B3A;
    }
    .pageStyle {
        float: right;
        margin-top: 23px;
        li {
            width: 32px;
            height: 32px;
        }
        .ant-pagination-item:hover {
            border-color: #C48B3A;
            a {
                color: #C48B3A;
            }
        }
        .ant-pagination-item-active {
            background: #C48B3A;
            border-color: #C48B3A;
            &:hover {
                a {
                    color: #FFFFFF;
                }
            }
            a {
                color: #FFFFFF;
            }
        }
    }
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #C48B3A;
}


.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: #C48B3A;
    border-color: #C48B3A;
}


.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}



.apply-btn {
    display: block;
    width: 92px;
    height: 32px;
    background: #C48B3A;
    border-radius: 2px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 16px;
    cursor: pointer;
    &:hover {
        color: #FFF;
    }
}
.users-breadcrumb {
    &.account-security-container {
        .detail-breadcrumb {
            left: -286px;
        }
    }
    .detail-breadcrumb {
        left: -254px;
        top: -71px;
    }
}
[ant-click-animating-without-extra-node='true']::after {
    box-shadow: none;
    opacity: 0;
}
.ant-btn-primary {
    text-shadow:none;
    box-shadow:none;
}
.ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
}
