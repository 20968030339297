.corporate-info-container {
    .corporate-info-content {
        .form-content {
            text-align: left;
            .ant-form-item-label {
                width: 158px;
            }
            .ant-form-item-control-input {
                max-width: 100%;
            }
            .input-address {
                .ant-form-item-control-input {
                    max-width: 520px;
                }
            }
            .input-name {
                .ant-form-item-control-input {
                    width: 440px;
                }
            }
            .input-phone {
                margin-top: 0;
                .ant-form-item-control-input {
                    width: 264px;
                    position: relative;
                    .suffix-text {
                        position: absolute;
                        right: -38px;
                    }
                }
            }
            .corporate-code {
                .ant-form-item-control-input {
                    width: 360px;
                }
            }
            .date-time {
                margin-left: 158px;
            }
            .cert-type-input {
                height: 34px;
            }
            .cert-upload-box {
                display: flex;
                .upload-item {
                    margin-right: 24px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .input-desc {
                        .desc-title {
                            font-size: 14px;
                            line-height: 22px;
                            color: #000000;
                            margin: 12px 0 8px;
                            text-align: center;
                        }
                    }
                    .ant-form-item-control {
                        width: 240px;
                        height: auto;
                        .ant-upload.upload-box {
                            height: 151px;
                        }
                    }
                }
            }

            .submit-btn-item {
                margin-top: 40px;
                .ant-form-item-control-input {
                    margin-left: 120px;
                    .submit-btn {
                        width: 74px;
                    }
                }
            }
        }
        .desc-col {
            text-align: left;
        }
    }
    .number-text {
        color: #ddb98d;
    }
}