.index-header {
    background: transparent;
    padding: 0;
    height: 60px;
    position: fixed;
    width: 100%;
    z-index: 100;
    .index-header-container {
        height: 100%;
        width: 100%;
        &.white-header {
            background: #FFF;
            .nav-box {
                .item-box {
                     &:hover {
                        font-weight: 500;
                        .line {
                            bottom: 0;
                        }
                        .link {
                            color: #C38B3A;
                        }
                    }
                    .link {
                        color: #2F3438;
                    }
                    .line {
                        background: #C38B3A;
                    }
                    .activeNav {
                        color: #C38B3A;
                        .line {
                            bottom: 0;
                        }
                    }
                }
            }
            .avatar-box {
                .user-name {
                    color: #2F3438;
                    &.has-line{
                        border-right: 1px solid rgba(47, 52, 56, 0.1);;
                    }
                    &.link-btn {
                        cursor: pointer;
                        &:hover {
                            //font-weight: 500;
                        }
                    }
                }
            }
        }
        .header-content {
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .img-box {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 60px;
                .header-logo {
                    width: 220px;
                }
            }
        }
        .nav-box {
            flex: 1;
            height: 100%;
            display: flex;
            .item-box {
                margin-right: 5vw;
                position: relative;
                overflow: hidden;
                &.active, &:hover {
                    font-weight: 500;
                    .line {
                        bottom: 0;
                    }
                }
                .link {
                    font-size: 14px;
                    color: #FFFFFF;
                    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                    display: block;
                    height: 60px;
                    line-height: 60px;
                }
                .activeNav {
                    color: #FFFFFF;
                    border-bottom: 4px solid #FFFFFF;
                }
                .line {
                    width: 100%;
                    height: 4px;
                    background: #FFFFFF;
                    margin-bottom: 0;
                    position: absolute;
                    left: 0;
                    bottom: -4px;
                    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                }
            }
        }
        .avatar-box {
            font-size: 14px;
            line-height: 14px;
            .login-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .user-name-box {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .avatar-img {
                width: 32px;
                height: 32px;
                font-size: 32px;
            }
            .personal-center {
                color: #C38B3A;
                padding: 0 32px 0 12px;
                font-size: 14px;
                line-height: 12px;
                display: inline-block;
                height: 12px;
                cursor: pointer;
            }
            .user-name {
                color: #FFFFFF;
                padding: 0 10px;
                font-size: 14px;
                line-height: 15px;
                display: inline-block;
                height: 16px;
                cursor: pointer;
                &.has-line {
                    border-right: 1px solid rgba(255, 255, 255, 0.5);
                }
                &.link-btn {
                    cursor: pointer;
                    &:hover {
                        //font-weight: 500;
                    }
                }
            }
        }
    }
}


