.agency-list {
    .list-content {
        .list-item {
            width: 100%;
            height: 116px;
           // line-height: 116px;
            border-bottom: 1px solid  rgba(47, 52, 56, 0.1);
            .text {
                font-size: 14px;
                color: #2F3438;
                padding: 0px 14px;
                &:nth-child(1) {
                    line-height: 116px;
                }
                &:not(:nth-child(1)) {
                    margin-top: 30px;
                }
            }
            .text-type {
                color: rgba(47,52,56,.6);
                margin-top: 8px;
            }
            .date-img {
                margin-bottom: 2px;
                margin-right: 5px;
            }
            .apply-time {
                display: table;
                margin-top:0;
                height: 116px;
                line-height: 30px;
                font-size: 14px;
                color: #2F3438;
                padding-left: 14px;
                div{
                    display: table-cell;
                    vertical-align: middle;
                }
            }
            .btn {
                color: #C38B3A;
                cursor: pointer;
                padding: 0px 24px;
                display: table;
                height: 116px;
                line-height: 30px;
                div{
                    display: table-cell;
                    vertical-align: middle;
                }
                p {
                    &:not(:nth-child(1)) {
                        margin-top: 6px;
                    }
                }
            }
        }
    }
    .total-data {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        color: rgba(47, 52, 56, 0.6);
        font-size: 14px;
        height: 80px;
        line-height: 80px;
        .total-num {
            color: #C38B3A;
        }
    }
}
.modal-distribute {
    .footer-btn {
        position: absolute;
        width: 100%;
        right: 0;
        border-top: 1px solid rgba(47, 52, 56, 0.06);
        text-align: right;
        background: #FBFBFB;
        height: 78px;
        .submit-btn {
            width: 74px;
            margin-top: 20px;
            &:nth-child(1) {
                margin-right: 12px;
                border: 1px solid #C38B3A;
                background: #ffffff;
                color: #C38B3A;
            }
            &:nth-child(2) {
                margin-right: 25px;
            }
        }
    }
}
.sub-account-list {
    .userName {
       color: rgba(47, 52, 56, 0.85);
    }
    .telephone {
        color: rgba(47, 52, 56, 0.6);
        margin-left: 4px;
    }
    .ant-radio-group {
        width: 408px;
        padding-right:10px;
    }
    .ant-radio-wrapper {
        width: 100%;
        .ant-radio {
           left:95%;
            margin-bottom: 24px;
        }
    }
}
