.confirm-list-container {
    margin-top: 60px;
    .page-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #2F3438;
        padding: 32px 0;
    }
    .list-box {
        background: #FFF;
        border-radius: 4px;
        padding: 24px;
        min-height: 480px;
        margin-bottom: 80px;
        .text {
            padding: 0 4px;
        }
        &.financing-confirm {
            .title0 {
                width: 17.5%
            }
            .title1 {
                width: 20.95%
            }
            .title2 {
                width: 14.85%
            }
            .title3 {
                width: 14.85%
            }
            .title4 {
                width: 18.95%
            }
            .title5 {
                width: 11.5%
            }
        }
        &.company-confirm {
            .title0 {
                width: 29%
            }
            .title1 {
                width: 29%
            }
            .title2 {
                width: 29%
            }
            .title3 {
                width: 13%
            }
        }
        .list-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #2F3438;
            margin-bottom: 16px;
        }
        .list-content {
            border: 1px solid rgba(47, 52, 56, 0.06);
            .title-box  {
                padding: 0 27px;
                background: #F7F7F7;
                height: 56px;
                .text {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 56px;
                    color: #2F3438;
                    text-align: left;
                    float: left;
                }
            }
            .list-desc {
                height: 56px;
                padding: 0 27px;
                .text {
                    float: left;
                    font-size: 14px;
                    line-height: 56px;
                    color: #2F3438;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    .submit-btn {
        width: 92px;
        height: 32px;
        background: #C48B3A;
        border-radius: 2px;
        text-align: center;
        font-size: 14px;
        line-height: 32px;
        color: #FFFFFF;
        margin: 32px auto 0;
        cursor: pointer;
    }
    .apply-amount {
        width: 750px;
        margin: 35px auto 40px;
        label {
            color: rgba(47, 52, 56, 0.85);
        }
        .ant-form-item-label {
            width:135px;
        }
        .apply-amount-num {
            display:flex;
            input {
                width: 240px;
            }
        }
        .unit-text {
            font-size: 14px;
            color: rgba(47, 52, 56, 0.65);
            line-height: 32px;
            margin-left: 8px;
        }
        .ant-form-item-control-input {
            max-width: 520px;
            textarea {
                height: 162px;
            }
        }
    }
}
