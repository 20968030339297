.login-container.reset-password-container {
    .login-content {
        width: 480px;
        .login-right {
            padding: 48px 84px;
            margin-left: 0;
            width: 100%;
            .login-title {
                margin-top: 0;
                text-align: center;
            }
            .left-desc {
                text-align: center;
                margin-bottom: 40px;
            }
            .ant-form-item {
                height: 88px;
                &.input-password-extra {
                    height: 110px;
                }
            }
        }
    }
    .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
        padding-bottom: 4px;
        line-height: 22px
    }
    .ant-form-item-label > label {
        height: 22px;
    }
    .ant-row {
        display: block;
    }
}
