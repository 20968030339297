.debt-finance {
    margin-left: 24px;
    min-height: 80vh;
    background: #FFFFFF;
    padding: 20px 32px;
    margin-bottom: 50px;
    .debt-content {
            .title {
                font-size: 20px;
                color: #2F3438;
                display: inline-block;
                border-bottom: 1px solid #C38B3A;
                padding-bottom:12px;
                position: relative;
                bottom: -1px;
        }
        .debt-detail-content,.tab-box {
            border-top: 1px solid rgba(47, 52, 56, 0.1);
        }
        .total-data {
            display: flex;
            justify-content: space-between;
            background: #ffffff;
            color: rgba(47, 52, 56, 0.6);
            font-size: 14px;
            height: 80px;
            line-height: 80px;
            .total-num {
                color: #C38B3A;
            }
        }
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                &:nth-child(1) {
                    .tab-item {
                        padding-left: 10px;
                        border-left: 0;
                    }
                }
            }
        }
        .tab-item {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            height: 16px;
            font-weight: normal;
            border-left: 1px solid rgba(47, 52, 56, 0.1);
            padding: 0 0 1px 30px;
            margin: 30px 30px 24px 0;
        }
        .ant-tabs-nav::before {
            border: 0;
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #C38B3A;
            font-weight: normal;
        }
        .ant-tabs-ink-bar {
            background: #C38B3A;
        }
        .ant-tabs-tab {
            color: rgba(47, 52, 56, 0.6);
            margin-right: 0;
        }
        .ant-tabs-tab:hover {
            color: #C38B3A;
        }
        .ant-tabs-ink-bar-animated {
            display: none
        }
    }
    .debt-detail-content {
        padding-top: 30px;
        .debt-line {
            padding-bottom: 24px;
            border-bottom: 1px solid rgba(47, 52, 56, 0.1);
        }
        .debt-other-line {
            padding-top: 24px;
            border-top: 1px solid rgba(47, 52, 56, 0.1);
        }
        .debt-row {
            margin-bottom: 24px;
            font-size: 14px;
            .name-title {
            color:rgba(47, 52, 56, 0.85);
            }
            .name-text {
                color: rgba(47, 52, 56, 0.65);
            }
            .name-text-other {
                padding-right: 150px;
                word-wrap: break-word;
                word-break: normal
            }
        }
        .btn-operate {
            .btn {
                width: 74px;
                height: 32px;
                padding: 5px 23px;
                background: #C48B3A;
                border-radius: 2px;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                font-weight:400;
                cursor: pointer;
            }
            .btn-reject {
                margin-left: 12px;
                background: #FFFFFF;
                color: #C38B3A;
                border: 1px solid #C38B3A;
            }
        }
    }
    .operate-btn a:hover{
        color:#C38B3A;
    }
    .product-status {
        p {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #C45900;
            margin-right: 8px;
        }
            .red {
                background: #EB0000;
            }
            .yellow {
                background: #FDA92B;
            }
            .green {
                background: #01F51A;
            }
            .red-black {
                background: #C45900;
            }
           .gray {
                 background: #BABABA;
            }
    }
    .search-box {
        background: #FFFFFF;
        padding-top: 32px;
        padding-bottom: 24px;
        border-top: 1px solid rgba(47, 52, 56, 0.1);
        position: relative;
        bottom: -1px;
        display: flex;
        .search-form {
            line-height: 30px;
            .search-title {
                margin-right: 12px;
                width:60px;
            }
            .search-input {
                width: 210px;
            }
            .search-select {
               width: 210px;
                margin-left: -30px;
            }
        }
        .btn {
            line-height: 30px;
        }
        .detail-btn {
            width: 65px;
            height: 32px;
            padding: 5px 10px;
            background: #C48B3A;
            margin-left: 8px;
            border-radius: 2px;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            font-weight:400;
            cursor: pointer;
            display: inline-block;
        }
        .reset-btn {
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            color: rgba(47, 52, 56, 0.65);
            margin-left: 20px;
            margin-right: 20px;
        }
        .add-btn {
            width: 88px;
        }
    }
    .debt-detail-content,.tab-box {
        border-top: 1px solid rgba(47, 52, 56, 0.1);
    }
}
