.info-tip-container {
    background: url('../../resource/images/login/login-bg.svg') no-repeat center bottom/100% auto, linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%);
    display: flex;
    min-height: calc(100vh - 120px);
    align-items: center;
    justify-content: center;
    .info-tip-content {
        background: #FFF;
        text-align: center;
        height: 600px;
        .img-tip {
            margin-top: 15%;
        }
        .title {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #2F3438;
            margin-top: 32px;
        }
        .desc {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: rgba(47, 52, 56, .6);
            margin-top: 8px;
        }
        .link-box {
            margin-top: 32px;
            .set-info, .back-btn {
                border-radius: 2px;
                width: 88px;
                height: 32px;
                display: inline-block;
                line-height: 32px;
            }
            .set-info {
                background: #C38B3A;
                font-size: 14px;
                color: #FFFFFF;
                margin-right: 8px;
            }
            .back-btn {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65);
                border: 1px solid #D9D9D9;
            }
        }
    }
}