.news-info-container {
    padding-top: 60px;
    background: #F8F8F8;
    .news-info-content {
        padding-bottom: 64px;
        .title-box {
            margin: 24px 0;
            a {
                &:hover {
                    color: #C48B3A;
                }
            }
        }
        .info-box {
            padding: 80px;
            background: #FFF;
            .news-title {
                font-weight: 500;
                font-size: 24px;
                line-height: 34px;
                color: #2F3438;
                text-align: center;
            }
            .time-box {
                text-align: center;
                margin-top: 24px;
                padding-bottom: 48px;
                border-bottom: 1px solid rgba(47, 52, 56, .1);
                span {
                    display: inline-block;
                }
                .tag {
                    height: 18px;
                    background: #FFF8E1;
                    border-radius: 2px;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: #94A1B8;
                    padding: 0 8px;
                    margin-right: 40px;
                }
                .time {
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: rgba(38, 40, 44, 0.45);
                }
            }
            .desc {
                margin-top: 40px;
                img {
                    border: 1px solid rgba(47, 52, 56, 0.05);
                }
            }
            ol li{
                list-style: decimal;
            }
            ul li {
                list-style: disc;
            }
        }
    }
    .file-field {
        font-weight: bold;
        padding: 12px 0;
        display: block;
        margin-top: 12px;
    }
    .file-row {
        width: 500px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 24px;
        margin-left: 8px;
    }
    .file-icon {
        color: rgba(0, 0, 0, 0.45);
        &:hover {
            color: rgba(0, 0, 0, 0.8);
        }
    }
    .file-name {
        width: 400px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
