.upload-container {
    width: 100%;
    height: 100%;
    position: relative;
    .ant-upload-list-item-info {
        display: none; //需要显示进度条但不显示文件名，文档中没找到 直接进行文件隐藏
    }
    .ant-upload-list-item-progress {
        top: 0;
        bottom: initial;
        padding-left: 0;
    }
    .ant-upload.upload-box {
        background: #FFF;
        height: 180px;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        &:hover {
            border-color: #d9d9d9;
        }
        .upload-dialog {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 240px;
            max-height: 180px;
            .icon-upload {
                //margin-top: 44px;
            }
            .upload-text {
                margin-top: 12px;
            }
            .file-type {
                width: 100%;
                height: 100%;
                //background: url('../../resource/images/upload/file-type-bg.svg') no-repeat center center;
                background-size: 101px;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                .file-image {
                    max-width: 237px;
                    max-height: 178px;
                }
            }
            .change-dialog {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(0, 0, 0, .5);
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .icon-change {
                    width: 40px;
                }
                .change-text {
                    color: #FFF;
                    font-size: 16px;
                }
            }
        }
        .ant-upload.ant-upload-drag .ant-upload-btn {
            position: relative;
        }
    }
    .error-text {
        //margin-left: 4px;
        color: #ed4c4c;
        font-size: 12px;
        margin-top: 0;
        //position: absolute;
        left: 0;
        bottom: -26px;
    }
    .ant-upload.ant-upload-drag .ant-upload {
        padding: 0;
    }
}
.id-card-upload {
    .upload-container {
        .file-image {
            max-height: 149px!important;
        }
    }
}