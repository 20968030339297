.agency-replenish-container.firm-register-container {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 120px);
    .firm-register-content {
        .agency-replenish-from {
            .ant-form-item-control-input {
                max-width: 100%;
            }
            .input-address {
                .ant-form-item-control-input {
                    width: 520px;
                }
            }
            .input-name {
                .ant-form-item-control-input {
                    width: 440px;
                }
            }
            .input-phone {
                margin-top: 0;
                .ant-form-item-control-input {
                    width: 264px;
                }
            }
            .ant-form-item-label {
                width: 120px;
            }
            .submit-btn-item {
                margin-top: 40px;
                .ant-form-item-control-input {
                    margin-left: 120px;
                    .submit-btn {
                        width: 74px;
                    }
                }
            }
        }
    }
    .number-text {
        color: #ddb98d;
    }
}