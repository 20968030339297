.register-agreement-container {
    .register-agreement-content {
        background: #FFF;
        margin-top: 108px;
        margin-bottom: 48px;
        padding: 80px;
        .agreement-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: #000000;
        }
        .item-title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-top: 48px;
        }
        .item-desc {
            font-size: 14px;
            line-height: 28px;
            color: #2F3438;
            margin-top: 16px;
        }
    }
}