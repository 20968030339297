.account-security-container {
    background: #FFFFFF;
    padding: 0 32px 32px;
    min-height: 540px;
    .account-security-content {
        .account-edit-item {
            margin-top: 32px;
            padding: 24px 32px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: 1px solid rgba(47, 52, 56, 0.1);
            box-sizing: border-box;
            border-radius: 2px;
           .img-box {
               width: 56px;
               height: 56px;
               border-radius: 50%;
               background: linear-gradient(357.97deg, #C38B3A -40.67%, #F7C072 148.58%);
               display: flex;
               align-items: center;
               justify-content: center;
               margin-right: 24px;
           }
            .text-box {
                .item-title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    color: #2F3438;
                }
                .item-desc {
                    font-size: 14px;
                    line-height: 24px;
                    color: rgba(47, 52, 56, .6);
                    span {
                        color: #e19e6b;
                    }
                }
            }
            .edit-btn {
                width: 88px;
                height: 32px;
                background: #C38B3A;
                border-radius: 2px;
                margin-left: auto;
                margin-right: 0;
                text-align: center;
                font-size: 14px;
                line-height: 32px;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }
}
.password-dialog {
    padding: 8px;
    .ant-form-item-control-input {
        width: 360px;
    }
    .ant-modal-footer {
        height: 66px;
        border-top: 1px solid rgba(47, 52, 56, 0.06);
        padding-top: 16px;
        padding-bottom: 16px;
    }
    &.phone-dialog {
        .ant-modal-body {
            margin-top: 16px;
            .steps-content {
                margin-top: 40px;
                .input-phone {
                    .ant-form-item-control-input {
                        width: 264px;
                    }
                }
                .number-code {
                    .ant-form-item-control-input {
                        width: 162px;
                    }
                }
                .success-tip-box {
                    margin-top: 71px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .text-box {
                        .title {
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 24px;
                            color: #2F3438;
                        }
                        .desc {
                            font-size: 14px;
                            line-height: 24px;
                            color: rgba(47, 52, 56, 0.4);
                            margin-top: 8px;
                        }
                        .close-btn {
                            background: #C38B3A;
                            border-radius: 2px;
                            width: 96px;
                            height: 32px;
                            text-align: center;
                            line-height: 32px;
                            font-size: 14px;
                            color: #FFFFFF;
                            cursor: pointer;
                            margin-top: 16px;
                        }
                    }
                }
            }
            .steps-action {
                margin-left: 102px;
                margin-top: 20px;
                padding-bottom: 78px;
                .close-btn {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 22px;
                    color: #C38B3A;
                    margin-left: 24px;
                    cursor: pointer;
                }
            }
        }
        .ant-modal-footer {
            display: none;
        }
    }
}