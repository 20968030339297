.login-container {
    width: 100%;
    min-height: calc(100vh - 120px);
    //min-height: calc(100vh - 64px);
    padding-top: 1px;
    background: url('../../resource/images/login/login-bg.svg') no-repeat center bottom/100% auto, linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    .login-content {
        width: 1000px;
        height: 572px;
        margin: auto;
        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(168, 168, 168, 0.25);
        .login-left {
            padding-left: 48px;
            background: url('../../resource/images/login/bg-aside.svg') no-repeat right center/auto, linear-gradient(180deg, #FBFBFB 0%, #F9F9F9 100%);
            .left-title {
                font-weight: 800;
                font-size: 32px;
                line-height: 42px;
                color: #2F3438;
                margin-top: 64px;
                margin-bottom: 16px;
            }
        }
        .left-desc {
            font-size: 14px;
            line-height: 22px;
            color: rgba(47, 52, 56, .6)
        }
        .login-left, .login-right {
            float: left;
            width: 50%;
            height: 100%;
        }
        .login-right {
            text-align: left;
            width: 312px;
            margin-left: 94px;
            .login-title {
                font-weight: 500;
                font-size: 28px;
                line-height: 42px;
                color: #000000;
                margin-top: 76px;
                margin-bottom: 8px;
            }
            .ant-form-item {
                height: 72px;
                margin-bottom: 0;
                &.submit-btn-box {
                    height: auto;
                }
                .ant-form-item-explain, .ant-form-item-extra {
                    line-height: 1.2;
                }
            }
            .ant-btn-primary {
                height: 40px;
                border-radius: 2px;
                width: 100%;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                background: #C48B3A;
                border-color: #C48B3A;
            }
            .ant-tabs-top > .ant-tabs-nav::before {
                border: 0;
            }

            .ant-tabs-nav-list {
                width: 100%;
            }

            .ant-tabs-tab {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                color: rgba(47, 52, 56, .6);
            }
            .ant-tabs-tab:hover {
                color: #C48B3A;
                svg {
                    path {
                        fill: #C48B3A;
                        opacity: 1;
                    }
                }
            }
            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #C48B3A;
                font-weight: normal;
            }
            .ant-tabs-ink-bar {
                background: #C48B3A;
            }
            .ant-tabs-tab.ant-tabs-tab-active {
                svg {
                    path {
                        fill: #C48B3A;
                        opacity: 1;
                    }
                }
            }
            .ant-tabs-tab {
                svg {
                    path {
                        transition: all 0.3s;
                    }
                }
            }
            .tab-box {
                margin-top: 16px;
            }
            .tab-item {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                line-height: 22px;
                font-weight: normal;
                .icon-tag {
                    margin-right: 12px;
                }
            }
            .number-code {
                .ant-form-item-control-input {
                    width: 202px;
                }
                .ant-input-suffix {
                    height: 40px;
                    top: 0;
                    right: -110px;
                }
                .img-code {
                    width: 101px;
                    height: 40px;
                    display: block;
                    cursor: pointer;
                }
            }
            .register-box {
                margin-top: 12px;
                text-align: right;
                .text-link {
                    font-size: 14px;
                    line-height: 12px;
                    color: rgba(61, 61, 61, .6);
                    display: inline-block;
                    cursor: pointer;
                    &:first-child {
                        padding-right: 8px;
                        border-right: 1px solid #D9D9D9;
                    }
                    &:last-child {
                        padding-left: 8px;
                    }
                }
            }
        }
    }
}
