.firm-register-container.retrieve-password-container {
    .firm-register-content {
        margin-top: 48px;
        .form-content {
            .left-from {
                padding: 0;
            }
            .confirm-input {
                border: 0;
            }
            .input-password {
                &.has-line {
                    padding-top: 40px;
                    height: 128px;
                    border-top: 1px solid rgba(47, 52, 56, 0.1);
                }
            }
            .input-phone {
                margin-top: 0;
            }
        }
    }
}