.authority-manage {
    margin-left: 24px;
    min-height: 80vh;
    background: #FFFFFF;
    padding: 20px 32px;
    margin-bottom: 50px;
    .title {
        font-size: 20px;
        color: #2F3438;
        display: inline-block;
        border-bottom: 1px solid #C38B3A;
        padding-bottom:12px;
        position: relative;
        bottom: -1px;
    }
    .tab-box {
        border-top: 1px solid rgba(47, 52, 56, 0.1);
    }
    .remark {
        width: 200px;
        word-break:break-all;
    }
    .total-data {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        color: rgba(47, 52, 56, 0.6);
        font-size: 14px;
        height: 80px;
        line-height: 80px;
        .total-num {
            color: #C38B3A;
        }
    }
    .tab-item {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 30px;
        font-weight: normal;
    }
    .ant-tabs-nav::before {
        border: 0;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #C38B3A;
        font-weight: normal;
    }
    .ant-tabs-ink-bar {
        background: #C38B3A;
    }
    .ant-tabs-tab {
        color: rgba(47, 52, 56, 0.6);
    }
    .ant-tabs-tab:hover {
        color: #C38B3A;
    }
    .user-content {
        background: #ffffff;
    }
    .add-btn {
        width: 92px;
        height: 32px;
        padding: 5px 10px;
        background: #C48B3A;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        font-weight:400;
        cursor: pointer;
        margin-bottom: 30px;
    }
    .add-btn-disabled {
        width: 92px;
        height: 32px;
        padding: 5px 10px;
        background: rgba( 47,52,56,.6);
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #E5E5E5;
        font-weight:400;
        cursor:not-allowed;
        margin-bottom: 30px;
    }
     .operate-btn {
         .ant-space-item {
             a {
                 border-right: 1px solid rgba(47, 52, 56, 0.1);
                 padding-right: 16px;
             }
             &:last-child {
                 a {
                     border: 0;
                 }
             }
         }
         .disabled-btn {
             color: gray;
             cursor: not-allowed;
             &:hover{
                 color:gray;
             }
         }
     }
    .operate-btn a:hover{
        color:#C38B3A;
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
        display:none;
    }
    table {
        .ant-table-cell {
            color: #2F3438;
            font-size: 14px;
        }
    }
}
.modal-add-content {
    .form-content .ant-form-item {
        height:88px;
        &.not-extra {
            height: 64px;
        }
    }
    margin-bottom: 90px;
    padding-left: 8px;
    padding-right: 108px;
    .add-from-content {
        .footer-btn {
            position: absolute;
            width: 100%;
            right: 0;
            border-top: 1px solid rgba(47, 52, 56, 0.06);
            text-align: right;
            margin-top: 40px;
            background: #FBFBFB;
            height: 78px;
            .submit-btn {
                width: 74px;
                margin-top: 20px;
                &:nth-child(1) {
                    margin-right: 12px;
                }
                &:nth-child(2) {
                    margin-right: 25px;
                }
            }

            .cancel-btn {
                border: 1px solid #C38B3A;
                background: #ffffff;
                color: #C38B3A;
            }
        }

        .ant-form-item-control-input {
            width: 100%;
            max-width: 100%
        }
        .ant-select {
            width: 264px;
        }
    }
    .input-remark {
        textarea {
            min-height: 76px;
        }
    }
   .ant-input-password, .ant-input {
        width:360px
    }
    .checkout-box {
        .ant-form-item-label {
           margin-top: -5px;
        }
        span {
        color:rgba(47, 52, 56, 0.65);
        }
        .ant-checkbox-wrapper {
            margin-right: 48px;
            margin-bottom: 24px;
            width: 120px;
        }
    }
    .edit-flex {
        display: flex;
        margin-bottom: 32px;
        font-size: 14px;
        .edit-title {
            width: 102px;
        }
        .edit-text {
            color: rgba(47, 52, 56, 0.65);
        }
    }
}
.info-success {
    .anticon-info-circle {
      display: none;
    }
    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
         margin-left: 0px;
    }
    .ant-modal-content {
        text-align: center;
    }
    .add-success {
        font-size: 24px;
        color: #2F3438;
        margin: 40px auto 8px;
    }
    .success-text {
        font-size: 14px;
        color:rgba(47, 52, 56, 0.4);
    }
    .ant-modal-confirm-btns {
        float: none;
        margin-top: 16px;
        margin-bottom: 20px;
    }
}
.info-disable {
    .disable-title {
        font-size: 16px;
        color: #2F3438;
    }
    .disable-text {
        font-size:14px;
        color: rgba(47, 52, 56, 0.4);
        margin-bottom: 5px;
    }
    .ant-modal-confirm-btns {
        .ant-btn {
            width: 74px;
            &:nth-child(1) {
                border: 1px solid #C38B3A;
                background: #ffffff;
                color: #C38B3A;
            }
        }
    }
}
.modal-edit-user {
    .footer-btn {
        position: absolute;
        width: 100%;
        right: 0;
        border-top: 1px solid rgba(47, 52, 56, 0.06);
        text-align: right;
        background: #FBFBFB;
        height: 78px;
        .submit-btn {
            width: 74px;
            margin-top: 20px;
            &:nth-child(1) {
                margin-right: 12px;
                border: 1px solid #C38B3A;
                background: #ffffff;
                color: #C38B3A;
            }
            &:nth-child(2) {
                margin-right: 25px;
            }
        }
    }
    .ant-form-item-control-input {
        width: 100%;
        max-width: 100%
    }
    .not-extra {
        margin-bottom: 120px;
    }
    .ant-select {
        width: 264px;
    }
    .auth-scope {
        margin-bottom: 68px;
    }
    .role-auth {
        color: rgba(47, 52, 56, 0.65);
    }
    .role-name {
        margin-bottom: 24px;
    }
}
.role-add {
    img  {
        width: 45px;
        margin-right:10px;
    }
}

