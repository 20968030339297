.profile-index-container {
    // 两个工作台页面公共样式在这
    margin-bottom: 44px;

    .account-info {
        background: #FFF;
        padding: 0 32px 32px;

        &.agency-info {
            background: transparent;
            padding: 0;
        }

        .title-box {
            height: 62px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(47, 52, 56, 0.1);

            .title {
                font-weight: 500;
                font-size: 20px;
                line-height: 62px;
                color: #2F3438;
                border-bottom: 1px solid #C38B3A;
            }

            .update-time {
                font-size: 14px;
                line-height: 24px;
                color: rgba(47, 52, 56, 0.4);
            }
        }

        .account-list {
            height: 111px;
            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .account-item {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .item-title {
                font-size: 16px;
                line-height: 24px;
                color: rgba(47, 52, 56, 1);
            }

            .item-number {
                font-size: 30px;
                line-height: 37px;
                text-align: left;
                color: #C38B3A;
            }

            .item-current {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                line-height: 24px;
                color: rgba(47, 52, 56, .4);
                .item-text {
                    margin-right: 24px;
                }
                .text-number {
                    color: rgba(195, 139, 58, 1);

                    span {
                        color: rgba(47, 52, 56, .4);
                    }
                }
            }

            .line {
                height: 100%;
                width: 1px;
                background: rgba(47, 52, 56, 0.06);
            }
        }
    }

    .recent-box {
        padding: 0 32px;
        margin-top: 24px;
        background: #FFF;

        &.has-list {
            .title-box {
                border-bottom: 1px solid rgba(47, 52, 56, 0.1);

                .title {
                    border-bottom: 1px solid #CC6904;
                }
            }
        }

        .news-list-box {
            padding-left: 0;
        }

        &.recent-transaction {
            .pagination-box {
                display: none;
            }
        }

        .detail-breadcrumb {
            display: none;
        }

        .title-box {
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 500;
                font-size: 20px;
                line-height: 64px;
                color: #2F3438;
            }

            .no-data-text {
                font-size: 14px;
                line-height: 24px;
                color: rgba(47, 52, 56, .4);
            }

            .show-all {
                font-size: 14px;
                line-height: 20px;
                color: #C38B3A;
            }
        }

        .project-list {
            //display: flex;
            align-items: center;
            justify-content: space-around;
            padding-top: 32px;
            padding-bottom: 34px;

            .project-item {
                //width: 258px;
                height: 252px;
                border: 1px solid rgba(47, 52, 56, 0.1);
                box-sizing: border-box;
                border-radius: 2px;

                .name {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #2F3438;
                    text-align: center;
                    margin-top: 8px;
                }

                .interest-rate {
                    font-weight: 500;
                    font-size: 30px;
                    line-height: 42px;
                    text-align: center;
                    color: #EB0000;
                    margin-top: 12px;
                    padding: 0 16px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                }

                .rate-title {
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: rgba(47, 52, 56, .6);
                    margin-top: 6px;
                }

                .max-text {
                    text-align: center;
                    font-size: 14px;
                    line-height: 14px;
                    color: rgba(47, 52, 56, 0.6);
                    margin-top: 20px;

                    span {
                        padding: 0 12px;

                        &:first-child {
                            display: inline-block;
                            line-height: 14px;
                            border-right: 1px solid #ebebeb;
                        }
                    }
                }

                .link-btn {
                    display: block;
                    margin: 22px auto 0;
                    width: 194px;
                    height: 32px;
                    background: #C48B3A;
                    border-radius: 2px;
                    font-size: 14px;
                    line-height: 32px;
                    color: #FFFFFF;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }

    .fast-menu-box {
        .menu-item {
            height: 86px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(47, 52, 56, 0.06);

            &:last-child {
                border: 0;
            }

            .item-box {
                display: flex;
                align-items: center;

                .item-icon {
                    margin-right: 16px;
                }

                .number {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #C38B3A;
                    margin-left: 16px;
                }
            }
        }
    }

    .agency-info-container {
        .agency-account-info {
            .account-info-content {
                background: #FFF;
                padding: 0 32px 32px;
            }
        }

        .agency-menu-info {
            .menu-info-content {
                background: #FFF;
                padding: 0 32px 3px;
                height: 100%;
            }
        }
    }

    .transaction {
        padding: 0 0 24px;
        margin: 24px 0 0;
        min-height: auto;

        .page-total-data {
            display: none;
        }
    }

    .my-message-container {
        padding: 0;
        margin: 0;

        .profile-title-box {
            display: none;
        }

        .all-read-text {
            display: none;
        }

        .pagination-content {
            display: none;
        }
    }

}
