.add-debt-finance {
    margin-left: 24px;
    min-height: 80vh;
    background: #FFFFFF;
    padding: 20px 32px;
    margin-bottom: 48px;
    .title {
        font-size: 20px;
        color: #2F3438;
        display: inline-block;
        border-bottom: 1px solid #C38B3A;
        padding-bottom:12px;
        position: relative;
        bottom: -1px;
    }
   .ant-upload.upload-box .upload-dialog .file-type .file-image {
        max-width: 235px;
        max-height: 178px;
    }
    .add-debt-content {
        border-top: 1px solid rgba(47, 52, 56, 0.1);
        padding-top: 33px;
        textarea,span {
          color: rgba(47, 52, 56, 0.65)
        }
        textarea {
            width: 520px;
            height: 162px;
        }
    }
    label {
        font-size: 14px;
        color:rgba(47, 52, 56, 0.85)
    }
    .to-text {
        font-size: 14px;
        color: rgba(47, 52, 56, 0.65);
        line-height:32px;
        margin-right: 15px;
        margin-left: 5px;
    }
    .unit-text {
        font-size: 14px;
        color: rgba(47, 52, 56, 0.65);
        line-height: 32px;
        margin-left: 8px;
    }
    .price-range {
        display: flex;
        height: 70px;

    }
    .item-height {
        height: 40px;
        margin-bottom:25px;
    }
    .contact-way {
        height: 70px;
        margin-bottom: 0;
    }
    .line {
        padding-top: 25px;
        border-top: 1px solid rgba(47, 52, 56, 0.06);;
    }
    .input-flex {
        .ant-form-item-control-input-content {
            display: flex;
           input {
               width: 180px;
           }
        }
    }
    .no-title {
        margin-left: 100px;
    }
    .ant-form-item-explain, .ant-form-item-extra {
        min-height: 24px;
        margin-top: 6px;
    }
    .footer-btn {
        margin-left: 110px;
        margin-top: 40px;
        background: #ffffff;
        height: 78px;
        .submit-btn {
            width: 74px;
            margin-top: 20px;
            &:nth-child(1) {
                margin-right: 12px;
                span {
                    color: #ffffff;
                }
            }
            &:nth-child(2) {
                margin-right: 25px;
            }
        }

        .cancel-btn {
            border: 1px solid #C38B3A;
            background: #ffffff;
            span {
                color: #C38B3A;
            }
        }
    }
    .select-type {
        width:345px;
    }
    .upload-box {
        width: 240px;
    }
    .ant-input-number  {
        width: 180px;
        .ant-input-number-handler-wrap {
            display:none
        }
        .ant-input-number-input-wrap {
            height: 32px;
            line-height: 32px;
        }
    }
}
