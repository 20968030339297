.contact-us-container {
    background-size: 100%;
    background: url('../../resource/images/index/img-contact-bg.svg') no-repeat left center;
    background-size: 100% 100%;
    background-color: #FFF;
    .contact-content {
        height: 346px;
        padding-top: 110px;
        .footer-item {
            .title {
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                color: #2F3438;
                margin-bottom: 24px;
            }
            .desc {
                font-size: 14px;
                line-height: 20px;
                color: rgba(47, 52, 56, .5);
                text-align: left;
                margin-bottom: 16px;
                display: block;
                .title-icon {
                    margin-right: 8px;
                }
            }
            a:hover{
                color: #C48B3A;
            }
        }
    }
}
