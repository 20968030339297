.ant-layout-header {
    background: transparent;
    padding: 0;
    height: 60px;
    position: relative;
    .author-header-container {
        height: 100%;
        width: 100%;
        background: #FFF;
        box-shadow: 0 4px 4px rgba(47, 52, 56, 0.05);
        .header-content {
            //max-width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .img-box {
                display: flex;
                align-items: center;
                justify-content: center;
                .header-logo {
                    margin-right: 12px;
                }
                .current-page {
                    display: inline-block;
                    font-size: 20px;
                    line-height: 28px;
                    color: #000000;
                    padding-left: 12px;
                    //border-left: 1px solid rgba(47, 52, 56, 0.1);
                }
            }
        }
        .avatar-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            .user-name-box {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .avatar-img {
                width: 32px;
                height: 32px;
                font-size: 32px;
            }
            .user-name {
                color: #26282C;
                padding: 0 8px 0 12px;
                border-right: 1px solid #EBEBEB;
            }
            .logout-btn {
                color: #C48B3A;
                padding: 0 8px;
                cursor: pointer;
            }
        }
        .login-aside {
            display: flex;
            align-items: center;
            .link-text {
                font-size: 14px;
                line-height: 12px;
                color: #2F3438;
                padding: 0 12px;
                &:first-child {
                    border-right: 1px solid rgba(47, 52, 56, 0.1);
                }
            }
        }
    }
}


