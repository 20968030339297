.transaction {
    margin-left: 24px;
    min-height: 80vh;
    background: #FFFFFF;
    padding: 20px 32px 30px;
    margin-bottom: 50px;
    .transaction-index-container {
        margin-top: -71px;

        .title {
            font-size: 20px;
            color: #2F3438;
            display: inline-block;
            border-bottom: 1px solid #C38B3A;
            padding-bottom: 12px;
            position: relative;
            bottom: -1px;
        }
        .title-detail {
            font-size: 20px;
            color: #2F3438;
            display: inline-block;
            margin-bottom: 24px;
        }
        .company-manage {
            border-top: 1px solid rgba(47, 52, 56, 0.1);
        }
        .transaction-progress {
            margin: 0 70px 40px;
            display: flex;
            :nth-child(1){
                .step-title {
                    margin-left: -10px;
                }
            }
            :nth-child(2) {
                .step-title {
                    margin-left: -28px;
               }
            }
            :nth-child(3) {
                .step-title {
                    margin-left: -10px;
                }
                .progress-img {
                    width: 120px;
            }
            }
            .line {
                margin: 0 15px;
            }
            .step-title {
                font-size: 14px;
                color: rgba(47,52,56,1);
                margin-bottom: 6px;
                font-weight: 500;
            }
            .step-title-close {
                font-size: 14px;
                color: rgba(47,52,56,1);
                margin-bottom: 6px;
                font-weight: 500;
                margin-left: -7px;
            }
            .step-time {
                font-size: 14px;
                color: rgba(47, 52, 56, 0.65);
                margin-left:-60px;
            }
            .progress-img {
                display: flex;
                margin-bottom: 16px;
            }
        }
        .split-line {
           margin-bottom: 24px;
            img {
                width: 100%;
            }
        }
        .product-info {
            text-align: center;
            line-height: 56px;
            border: 1px solid rgba(47, 52, 56, 0.06);
        }
        .product-title {
            background: #F7F7F7;
            &:nth-child(1) {
               border-bottom: 0px;
                border-right:0px;
            }
        }
        .product-name {
            color: #2f3438;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
        }
        .border-bottom {
            border-top: 0px;
        }
        .detail-bottom {
            display: flex;
            justify-content: space-between;
        }
        .go-detail {
            color:#C38B3A;
            cursor: pointer;
        }
    }
    .ant-tabs-nav {
        margin-bottom:0px;
    }
    .ant-tabs-nav-list {
        .ant-tabs-tab {
            &:nth-child(1) {
                .tab-item {
                    padding-left: 10px;
                    border-left: 0;
                }
            }
        }
    }
        .tab-item {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            height: 16px;
            font-weight: normal;
            border-left: 1px solid rgba(47, 52, 56, 0.1);
            padding: 0 0 1px 30px;
            margin: 30px 30px 24px 0;
        }
        .ant-tabs-nav::before {
            border: 0;
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #C38B3A;
            font-weight: normal;
        }
        .ant-tabs-ink-bar {
            background: #C38B3A;
        }
        .ant-tabs-tab {
            color: rgba(47, 52, 56, 0.6);
            margin-right: 0;
        }
        .ant-tabs-tab:hover {
            color: #C38B3A;
        }
        .ant-tabs-ink-bar-animated {
            display: none
        }
        .transaction-list {
    }
    .transaction-header-content {
        width: 100%;
        height: 56px;
        background: #F7F7F7;
        border: 1px solid rgba(47, 52, 56, 0.1);
        border-bottom: 0;
        box-sizing: border-box;
        .text {
            font-size: 14px;
            color: #2F3438;
            font-weight: 500;
            line-height: 56px;
            padding: 0px 14px;
        }
    }
    .list-header {
        display:  flex;
        background: #F7F7F7;
        margin-top: 16px;
        height: 48px;
        line-height: 48px;
        padding: 0 24px;
        border: 1px solid #EEEEEE;
        align-items: center;
        .list-header-title {
            font-size: 14px;
            color: rgba(47, 52, 56, 0.5);
            .text {
            color:#2F3438;
            }
         }
        .transaction-num {
            margin-right: 100px;
        }
        .transaction-time {
            margin-right: 45px;
        }
        .transaction-validity {
            color: rgba(47, 52, 56, 0.5);
            img {
                margin-right:10px;
            }
        }
    }
    .list-detail {
        display: flex;
        height:  112px;
        align-items: center;
        padding: 0 24px;
        border: 1px solid #EEEEEE;
        border-top: 0;
        font-size:14px;
        .product-name {
            display: flex;
            align-items: center;
            color:#2F3438;
            font-weight: 500;
            border-right: 1px solid #EEEEEE;
            height:  112px;
            padding-right: 20px;
            img {
                width: 84px;
                height: 64px;
                border: 1px solid #EAEBEB;
                margin-right: 16px;
                object-fit: contain;
                padding: 2px;
            }
            .type {
               color:rgba(47,52,56,.6);
                font-weight: normal;
            }
        }
        .name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            width: 150px;
        }
        .company-name {
            color: #2F3438;
            border-right: 1px solid #EEEEEE;
            height:  112px;
            padding: 0 20px;
            text-align: center;
            font-weight: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            div {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
                width: 220px;
            }
        }
       .price-range {
            color:#EB0000;
            border-right: 1px solid #EEEEEE;
            height:  112px;
            padding-right: 20px;
            text-align: center;
           font-weight: normal;
           display: flex;
           align-items: center;
           justify-content: center;
        }
        .btn-operate {
            margin-top:10px;
            padding-left: 45px;
            position: relative;;
            .btn {
                width: 88px;
                height: 32px;
                background: #C48B3A;
                border-radius: 2px;
                font-size: 14px;
                line-height: 32px;
                text-align: center;
                color: #FFFFFF;
                font-weight:400;
                cursor: pointer;
            }
            .btn-close {
                margin-top:5px;
                background: #FFFFFF;
                color: #C38B3A;
            }
        }
        .list-status-btn {
            width: 67px;
            height: 24px;
            border: 1px solid #F09F26;
            box-sizing: border-box;
            border-radius: 80px;
            text-align: center;
            font-size: 14px;
            line-height: 24px;
            color: #F09F26;
            position: absolute;
            top: -63px;
            left: 65px;

        }
    }
    .applicant {
        font-size: 14px;
        color: rgba(47,52,56,1);
    }
    .apply-title {
        font-size: 14px;
        color: rgba(47, 52, 56, 0.65);
        margin-left: 24px;
        img {
            margin-right: 6px;
        }
    }
    .apply-title-width {
        width: 70%;
        word-break: break-all;
    }
    .applicant-enterprise {
        margin-bottom: 24px;
        display: flex;
        .item-label-text {
            min-width: 58px;
        }
    }
    .applicant-enterprise-memo{
        display: flex;
        width: 260%;
        margin-top: 25px;
    }
    .apply-memo{
        word-break: break-all;
        width: 75%;
        font-size: 14px;
        color: rgba(47, 52, 56, 0.65);
        margin-left: 55px;
    }
    .applicant-result {
        display: flex;
        margin-bottom: 24px;
    }
    .result-title-price,.result-title {
        margin-left: 24px;
        margin-top:4px;
        margin-bottom: 24px;
        border: 1px solid rgba(47, 52, 56, 0.06)
    }
    .result-title-price {
        width: 200px;
    }
    .price {
        width: 200px;
        height: 56px;
        line-height: 56px;
        text-align: center;
       // border: 1px solid rgba(47, 52, 56, 0.06);
    }
    .price-title {
        background: #F7F7F7;
        border-bottom: 0;
        font-weight: 500;
    }
    .price-num {
        border-top: 0;
    }
    .transaction-detail {
        margin-bottom: 15px;
        .product-status-info {
            text-align: right;
            height: 160px;
            .close-btn {
                display: inline-block;
                width: 88px;
                height: 32px;
                background: #C48B3A;
                border-radius: 2px;
                font-size: 14px;
                line-height: 32px;
                color: #FFFFFF;
                font-weight:400;
                cursor: pointer;
                text-align: center;
            }
            .close-status {
                margin-top: 26px;
            }
            .status-text {
                font-size: 20px;
                color: #2F3438;
                margin: 6px auto;
            }
        }
    }
}
