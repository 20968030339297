.news-list-container {
    .title {
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        color: #2F3438;
    }
    .desc {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        margin-top: 8px;
        color: rgba(47, 52, 56, .6)
    }
    .news-list-banner-container {
        width: 100%;
        height: 480px;
        margin-top: 60px;
        background: #200804;
        background: url('../../resource/images/index/banner-news-list.svg') no-repeat center center;
        background-size: cover;
        .banner-content {
            height: 100%;
            margin: 0 auto;
        }
    }
    .news-list-content {
        background: #F8F8F8;
        padding-top: 1px;
        padding-bottom: 64px;
        .search-box {
            width: 822px;
            margin: 40px auto;
            display: flex;
            align-items: center;
            justify-content: center;
            .search-input {
                width: 552px;
            }
            .search-btn {
                width: 70px;
                height: 40px;
                font-size: 16px;
                line-height: 40px;
                color: #FFF;
                text-align: center;
                background: #C38B3A;
                border-radius: 0 2px 2px 0;
                cursor: pointer;
            }
        }
        ol li{
            list-style: decimal;
        }
        ul li {
            list-style: disc;
        }
    }
}